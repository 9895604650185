import {Component, OnDestroy, OnInit} from '@angular/core';
import {DatabaseService} from "../../../services/db/database.service";
import {ActivatedRoute} from "@angular/router";
import {DbKeys} from "../../../services/db/config/db-config";
import {News} from "../../../models/news";
import {DomSanitizer} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.scss']
})
export class EditNewsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  newsRow: News;
  isLoggedIn: boolean;
  viewMode = true;

  constructor(
    private db: DatabaseService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
      let id = params['id'];
      if(id) {
        this.db.getdata(DbKeys.news)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
          next: (data) => {
            // @ts-ignore
            let element = data.find(element => element.payload.doc.id === id);
            if(element) {
              this.newsRow = {
                html: this.sanitizer.bypassSecurityTrustHtml(element.payload.doc.get("html")),
                order: element.payload.doc.get("order"),
                id: element.payload.doc.id
              } as News
            }
          },
          complete: () => {
          },
          error: () => {
            this.snackbar.open("Erreur lors du chargement de la news.", "Compris")
          },
        });
      } else {
        this.snackbar.open("Erreur lors du chargement de la news.", "Compris");
      }
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {DatabaseService} from "../../../services/db/database.service";
import {DbKeys} from "../../../services/db/config/db-config";
import {FileData, FileDataFactory} from "../../../models/fileData";
import {FileService} from "../../../services/file/file.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthentificationService} from "../../../services/authentification.service";
import {StringUtils} from "../../../models/utils/string-utils";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-commentary-list',
  templateUrl: './commentary-list.component.html',
  styleUrls: ['./commentary-list.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class CommentaryListComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  faTrash = faTrash;
  results: FileData[];

  constructor(
    private db: DatabaseService,
    private files: FileService,
    private snackbar: MatSnackBar,
    private auth: AuthentificationService
  ) { }

  getDateSmallFormat = StringUtils.getDateSmallFormat;

  ngOnInit(): void {
    this.db.getdata(DbKeys.commentary)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
      this.results = data.map(result => FileDataFactory.createFromDoc(result)).sort((a, b) => b.date.getTime() - a.date.getTime());
      return data;
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  delete(row: FileData) {

    this.files.deleteFile(row.url)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: () => {

      },
      error: () => {
        this.deleteCommentary(row);
      },
      complete: () => {
        this.deleteCommentary(row);
      }
    })
  }

  private deleteCommentary(row: FileData) {
    this.db.deleteData(DbKeys.commentary, row.id)
      .then(() => this.snackbar.open("Suppression réussie.", "Fermer", {duration: 3000}))
      .catch(() => this.snackbar.open("Erreur de suppression.", "Fermer", {duration: 3000}));
  }

  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }
}

import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {DbKeys} from "../db/config/db-config";
import {Player} from "../../models/competition/player";
import {map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CompetitionService {

  constructor(private firestore: AngularFirestore) { }

  // Create a new player in Firestore
  createPlayer(player: Player) {
    // Assuming 'players' is the collection where player data is stored
    return this.firestore.collection(DbKeys.competitionPlayers).add({
      name: player.name
    });
  }

  // Edit an existing player's data in Firestore
  editPlayer(player: Player) {
    // Assuming each player document is named after the player's id
    return this.firestore.doc(`${DbKeys.competitionPlayers}/${player.id}`).update(player.toData());
  }

  // Delete a player from Firestore
  deletePlayer(player: Player) {
    // Assuming each player document is named after the player's id
    return this.firestore.doc(`${DbKeys.competitionPlayers}/${player.id}`).delete();
  }

  // Fetch a single player's data by id
  getPlayer(id: string) {
    // Returns an Observable of player data
    return this.firestore.doc<Player>(`${DbKeys.competitionPlayers}/${id}`).valueChanges();
  }

  // Fetch all players' data
  getPlayers() {
    return this.firestore.collection<Player>(DbKeys.competitionPlayers)
      .snapshotChanges()
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data() as { name: string; };
        const id = a.payload.doc.id;
        // Use the static factory method to create a new Player instance
        return Player.fromData({ ...data, id });
      })));


  }


}

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {Router} from "@angular/router";
import { CampService } from 'src/app/services/db/camp.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ICamp, ICampResult} from "../../../../models/camp";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DeleteCampResultComponent} from "../delete-camp-result/delete-camp-result.component";

@Component({
  selector: 'app-update-camp-result',
  templateUrl: './update-camp-results.component.html',
  styleUrls: ['./update-camp-results.component.scss'],
})
export class UpdateCampResultsComponent implements OnInit, OnDestroy {
  //private teams: ITeam[];
  //teamScores: ITeamScore[] = [];
  allDates: Date[] = [];
  selectedDate: Date;
  formGroup: FormGroup;
  private camp: ICamp;
  resultForDate: ICampResult | null;
  idToPlayer: Map<any, any>;
  destroy$ = new Subject<boolean>();

  constructor(
    private campService: CampService,
    private fb: FormBuilder,
    private router: Router,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {campId: string, camp: ICamp},
    public dialogRef: MatDialogRef<UpdateCampResultsComponent>,
    private snackBar: MatSnackBar
  ) {
    this.formGroup = this.fb.group({});
  }

  ngOnInit(): void {
    this.camp = this.data.camp;
    this.idToPlayer = new Map();
    this.campService.getCampPlayers(this.camp.id || '')
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
      console.log("players")
      data.forEach(player => {
        this.idToPlayer.set(player.id, player);
      });
    });

    this.getAllDates();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getAllDates() {
    this.allDates = this.camp.results!
      .map(campResult => campResult.date)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.getTime() - b.getTime());
  }

//Format date jj/MM

  formatDate(date: Date): string {
    return date.getDate() + "/" + (date.getMonth() + 1);
  }

  onChangeDate(): void {
    //reset form
    this.formGroup = this.fb.group({});
   //find the right resukt oon day and month
    this.resultForDate =
      this.camp.results!.find(campResult => campResult.date.getDate() === this.selectedDate.getDate()
      && campResult.date.getMonth() === this.selectedDate.getMonth()) || null;

    this.resultForDate?.scores?.forEach((score, index) => {
      this.formGroup.addControl(score.playerId, this.fb.control(score.score, [Validators.required]));
    });
  }

  getPlayerName(playerId: string): string {
    let player = this.idToPlayer?.get(playerId);
    return player.firstName + " " + player.lastName;
  };

  onSumbitResults() {


    if (this.formGroup.valid) {
      let values = this.formGroup.value;
      //Updates the scores in resultFOrDate
      let oldResult = Object.assign({}, this.resultForDate);
      this.resultForDate!.scores!.forEach((score, index) => {
        let playerId = score.playerId;
        score.score = values[playerId];
      });

      this.campService.updateCampResults(this.camp.id!, this.camp.results!)
        .then(
          () => {
            this.snackBar.open("Résultats mis à jour", "Fermer", {duration: 2000})
            this.dialogRef.close(this.camp);

          },
          () => {
            this.snackBar.open("Erreur lors de la mise à jour des résultats", "Fermer", {duration: 2000})
            this.dialogRef.close();
          }
        );

    }
  }

  onDeleteResults() {
    let deleteDialog = this.matDialog.open(DeleteCampResultComponent, {
      data: {campId: this.camp.id, result: this.resultForDate},
    });
    deleteDialog.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isDelete) => {
      if(isDelete) {
        this.camp.results = this.camp.results!.filter(result => result.date.getTime() !== this.resultForDate!.date.getTime());
        this.getAllDates();
        this.formGroup.reset();
        this.resultForDate = null;
        this.dialogRef.close(this.camp);
      }
    });
  }
}


import { throwDialogContentAlreadyAttachedError } from '@angular/cdk/dialog';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { ICamp } from 'src/app/models/camp';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { CampService } from 'src/app/services/db/camp.service';
import { ContentTypes } from 'src/app/services/db/config/content-types';
import { DbKeys } from 'src/app/services/db/config/db-config';
import { DatabaseService } from 'src/app/services/db/database.service';
import { FileService } from 'src/app/services/file/file.service';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";


@Component({
  selector: 'app-camp-program',
  templateUrl: './camp-program.component.html',
  styleUrls: ['./camp-program.component.scss']
})
export class CampProgramComponent implements OnInit {

  @Input() campId: string;
  @Input() contentType : ContentTypes;
  @Input() dbKey : DbKeys;
  @Input() title: string;
  @Input() placeholder: string;

  isLoggedIn: BehaviorSubject<boolean>;
  url: SafeResourceUrl;
  //private readonly urlText: string;
  isLoading: boolean;
  isDisabled = false;

  fileForm = this.fb.group({
    file: [undefined, Validators.required],
    fileName: ["", Validators.required],
  });

  submited: boolean;
  constructor(
    private fb: FormBuilder,
    private files: FileService,
    private db: DatabaseService,
    private snackbar: MatSnackBar,
    private auth: AuthentificationService,
    private campService: CampService,
    private sanitizer: DomSanitizer
  ) {
    this.isLoading = true;
   }

  ngOnInit(): void {
    this.isLoggedIn = this.auth.isAuthenticated$;
    this.fileForm = this.fb.group({
      file: [undefined, Validators.required],
      fileName: ["", Validators.required],
    });
    this.getCurrentProgram();
  }

  onSubmit($any: any) {
    this.submited = true;
    this.disableButton();
    if(this.fileForm.valid) {
      this.files.uploadFile(this.file.value as unknown as File, `/${this.dbKey}`, this.contentType).then(
        (res) => {
          res.ref.getDownloadURL().then(
            (url => {
              this.campService.getCampProgram(this.campId).then(programs => {
                if(programs.length){
                  programs.forEach(program => {
                    this.db.deleteData(this.dbKey, program.id)
                      .then(r => {  })
                      .catch(e => { console.log(e); });
                    this.files.deleteFile(program.url);
                  })
                }
                this.db.addData({url: url, campId: this.campId}, this.dbKey).then(
                  () => {
                    this.snackbar.open(`Programme ajouté !`, "Compris");
                    this.getCurrentProgram();
                    this.enableButton();
                  },
                  () => {
                    this.snackbar.open("Attention ! Erreur lors de l'ajout du fichier.", "Compris");
                    this.enableButton();
                  })
              })
          }));
        },
        () => {this.snackbar.open("Attention ! Erreur lors de l'ajout du fichier.", "Compris")}
      )

    }
  }

  getCurrentProgram(){
    this.campService.getCampProgram(this.campId).then(programs => {

      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(programs[0].url);
    });
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target?.files[0];
      this.fileForm.patchValue({
        file: file
      });
    }
  }

  getfile(): FormControl {
    return this.fileForm.controls['file'];
  }

  load() {
    this.isLoading = false;
  }

  disableButton() {
    this.isDisabled = true;
  }

  enableButton() {
    this.isDisabled = false;
  }

  get fileName(): FormControl {
    return this.fileForm.controls['fileName'];
  }

  get file(): FormControl {
    return this.fileForm.controls['file'];
  }
}

import {Injectable} from '@angular/core';
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {ContentTypes} from "../db/config/content-types";
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private storage: AngularFireStorage) { }

  public uploadResult(file: File) {

    let filePath = "/resultats/" + file.name;
    const fileRef = this.storage.ref(filePath);
    return this.storage.upload(filePath, file, {contentType: "text/html"});
  }

  public uploadCommentary(file : File) {
    let filePath = "/commentary/" + file.name;
    const fileRef = this.storage.ref(filePath);
    return this.storage.upload(filePath, file, {contentType: "application/pdf"});
  }

  public uploadFile(file: File, filePath: string, contentType: ContentTypes) {
    filePath = `${filePath}/${file.name}`;
    return this.storage.upload(filePath, file, {contentType: contentType.toString()});
  }

  public deleteFile(downloadUrl: string) {
    return this.storage.refFromURL(downloadUrl).delete();
  }

  getHTMLFileList() {
    return this.storage.ref("/resultats").listAll();
  }

  getPDFFileList() {
    return this.storage.ref("/commentary").listAll();
  }

    async getFileText(fullPath: string): Promise<string> {
      try {
        const ref = this.storage.ref(fullPath);
        const url = await lastValueFrom(ref.getDownloadURL()); // Convert Observable to Promise
        const response = await fetch(url); // Use the URL to fetch the file content
        if (!response.ok) throw new Error('Network response was not ok.');
        return await response.text(); // Return the text content
      } catch (error) {
        console.error("Failed to fetch file:", error);
        throw error; // Re-throw the error to be handled by the caller
      }
    }

}

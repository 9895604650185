import { Component, NgModule, OnInit } from '@angular/core';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {AuthentificationService} from "../../services/authentification.service";
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
//import { ScreenService } from 'src/app/services/screen/screen.service';
import { CommonModule } from '@angular/common';
import { ScreenService } from 'src/app/services/screen/screen.service';
import {BehaviorSubject} from "rxjs";


@Component({
  selector: 'app-new-mobile-sidenav',
  templateUrl: './new-mobile-sidenav.component.html',
  styleUrls: ['./new-mobile-sidenav.component.scss'],
})

export class NewMobileSidenavComponent implements OnInit {
  faBars = faBars;
  faGear = faGear;
  faRightFromBracket = faRightFromBracket;
  isLoggedIn: BehaviorSubject<boolean>;

  constructor(
    private authService: AuthentificationService,
    private router: Router,
    ) {}

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isAuthenticated$;
  }

  logout() {
    this.authService.logOut().then(r => console.log("Logged out."));
  }


  login() {
    const currentUrl = this.router.url;
    this.router.navigate(['/login', { previousRoute: currentUrl }]);
  }

  test(){

  }
}

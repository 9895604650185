import {Injectable} from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {DbKeys} from "./config/db-config";
import {firstValueFrom} from "rxjs";
import {RouteTitle} from "../../models/route-title";
import {AuthentificationService} from "../authentification.service";

@Injectable({
  providedIn: 'root'
})
export class DynamicTitleService {

  constructor(
    private firestore: AngularFirestore
  ) {

  }

  async saveTitleByRoute(route: string, title: string) {
    const documentId = encodeURIComponent(route); // Sanitize the route to use as a document ID
    const data = { title };

    try {
      await this.firestore.collection(DbKeys.routeTitles)
        .doc(documentId)
        .set(data, { merge: true });

    } catch (error) {
      console.error('Error saving document: ', error);
    }
  }

  async getTitleByRoute(route: string): Promise<RouteTitle | null> {
    try {
      const docRef = this.firestore.collection(DbKeys.routeTitles).doc(route);
      const docSnapshot = await firstValueFrom(docRef.get());
      if (docSnapshot.exists) {
        const data = docSnapshot.data() as RouteTitle;
        return data;
      }

      return null;
    } catch (error) {
      console.error('Error retrieving document:', error);
      return null;
    }
  }


}

import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ICamp} from "../../../models/camp";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-authorize-camp',
  templateUrl: './authorize-camp.component.html',
  styleUrls: ['./authorize-camp.component.scss']
})
export class AuthorizeCampComponent implements OnInit {
  @Input() camp: ICamp;
  @Output() campAccess = new EventEmitter<boolean>();

  accessForm = this.getAccessForm();
  submitted = false;
  wrongPassword = false;

  constructor(
    private formBuilder: FormBuilder,
    private snakcbar: MatSnackBar,
  ) { }

  private getAccessForm() {
    return this.formBuilder.group({
      password: ['', Validators.required],
    });
  }

  get password() {
    return this.accessForm.get('password') as FormControl;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['camp'] && changes['camp'].currentValue) {
      const password = localStorage.getItem(changes['camp'].currentValue.id || '');
      if (password == this.camp.password) {
        this.campAccess.emit(true);
      }
    }
  }


  ngOnInit(): void {

  }

  onSubmit() {

    if(this.accessForm.valid) {
      let password = this.password.value;
      console.log(this.camp.password)
      if(password === this.camp.password) {
        localStorage.setItem(this.camp.id || '', password);
        this.campAccess.emit(true);
      } else {
        this.snakcbar.open("Mot de passe erroné", "Close", {
          duration: 2000,
        });
      }
    }
  }
}

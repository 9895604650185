import {Component, Input, OnInit} from '@angular/core';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {AuthentificationService} from "../../../services/authentification.service";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Location} from "@angular/common";


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  host: {'class': 'styled-background base-host-center'}
})
export class LoginFormComponent implements OnInit {
  loginForm: FormGroup;
  submited: boolean;
  private returnUrl: string;
  private previousRoute: string;

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  constructor(
    private authService: AuthentificationService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private location: Location
    ) {
    this.submited = false;
    this.loginForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
    });

  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
    });
    this.previousRoute = this.route.snapshot.paramMap.get('previousRoute') || '/';
  }

  $login(value: any) {
    this.submited = true;
    this.authService.login(value.email, value.password).then(
      () => {
        this.router.navigate([this.previousRoute]);
      },
      () => {
        this.snackbar.open("Erreur de connexion", "Compris")
      }
    );
  }
}

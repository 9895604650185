import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthentificationService} from "../../../services/authentification.service";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Editor, Toolbar} from "ngx-editor";
import {msWordDefaultColorPalette} from "../../rte/color-presets";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CampInformation, CampService} from "../../../services/db/camp.service";

@Component({
  selector: 'app-camp-informations',
  templateUrl: './camp-informations.component.html',
  styleUrls: ['./camp-informations.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class CampInformationsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  @Input() campId: string;
  isLoggedIn: BehaviorSubject<boolean>;
  isEditMode = true;

  form: FormGroup;
  editor: Editor;
  submited: boolean;
  colorPresets = msWordDefaultColorPalette;
  htmlText: string | null = ''
  safeHtml: SafeHtml
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  private docId: string | undefined;


  constructor(
    private authService: AuthentificationService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder,
    private campService: CampService,
  ) {

    this.editor = new Editor();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      html: new FormControl('', {validators: [Validators.required, Validators.minLength(1)]})
    });

    this.isLoggedIn = this.authService.isAuthenticated$;

    this.campService.getCampinformations(this.campId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
      this.htmlText = data.html;
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.htmlText);
      this.form.get('html')?.setValue(data.html);
      this.docId = data.id;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onSubmit() {
    this.submited = true;
    if (this.form.valid) {

      if (!this.docId) {
        let campInfo = {campId: this.campId, html: this.html} as CampInformation;
        this.campService.addCampInformation(campInfo).then(() => {
            this.snackbar.open('Informations du camp enregistrées', 'Fermer', {duration: 2000});
          },
          () => {
            this.snackbar.open('Erreur lors de l\'enregistrement des informations du camp', 'Fermer', {duration: 2000})
          }
        );
      } else {
        let campInfo = {id: this.docId, campId: this.campId, html: this.html} as CampInformation;
        this.campService.updateCampInformation(campInfo).then(() => {
            this.snackbar.open('Informations du camp enregistrées', 'Fermer', {duration: 2000});
          },
          () => {
            this.snackbar.open('Erreur lors de l\'enregistrement des informations du camp', 'Fermer', {duration: 2000})
          }
        );
      }
    }
  }

  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }

  get html() {
    return this.form.get('html')?.value;
  }
}

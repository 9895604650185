import {Component, OnDestroy, OnInit} from '@angular/core';
import {TeamService} from "../../../services/db/team.service";
import {ITeam} from "../../../models/ITeam";
import {AbstractControl, FormBuilder, FormGroup, Validator, Validators} from "@angular/forms";
import {notZeroValidator, positiveValueValidator} from "../validators/notZeroValidator";
import {IResult} from "../../../models/IResult";
import { MatSnackBar } from '@angular/material/snack-bar';
import {Subject, Subscription, takeUntil} from "rxjs";

@Component({
  selector: 'app-create-team-result',
  templateUrl: './create-team-result.component.html',
  styleUrls: ['./create-team-result.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class CreateTeamResultComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  teams: ITeam[];
  resultForm: FormGroup;
  private teamSuscription: Subscription;

  constructor(
    private teamService: TeamService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar
  ) {
    this.resultForm = this.fb.group({
      date: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.teamSuscription = this.teamService.getTeams()
      .pipe(takeUntil(this.destroy$))
      .subscribe(teams => {
      this.teams = teams;
      this.createForm();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private createForm() {

    this.teams.forEach(team => {
      if (team.id != null) {
        this.resultForm.addControl(team.id, this.fb.control(0, /*[Validators.required, notZeroValidator(), positiveValueValidator()]*/));
      }
    });

  }

  onSubmit() {
    if(true /*!this.resultForm.invalid*/) {
      const date = new Date(this.resultForm.value.date);

      const promises: Promise<void>[] = [];
      this.teams.forEach(team => {
        const teamId = team.id;
        // @ts-ignore
        const score = this.resultForm.value[teamId];
        let result = {date: date, score: score} as IResult;
        //Add all the promises in an array and resolve them all
        promises.push(this.teamService.addResultToTeam(result, teamId as string));
      });
      Promise.all(promises).then(
        () => {this.snackbar.open("Résultat ajouté", "OK", {duration: 2000})},
        (error) => {

          this.snackbar.open("Impossible d'ajouter deux résultats le même jour.", "OK", {duration: 2000})});
    }
  }

  get date(){
    return this.resultForm.get("date");
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {ContentTypes} from "../../../services/db/config/content-types";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {FileService} from "../../../services/file/file.service";
import {DbKeys} from "../../../services/db/config/db-config";
import {CampService} from "../../../services/db/camp.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-add-camp-sequency',
  templateUrl: './add-camp-sequency.component.html',
  styleUrls: ['./add-camp-sequency.component.scss']
})
export class AddCampSequencyComponent implements OnInit {
  contentType = ContentTypes.pdf;

  formGroup = this.fb.group({
    title: ['', Validators.required],
    fileDate: [undefined, Validators.required],
    file: [null, Validators.required],
  });


  constructor(
    private fb: FormBuilder,
    private fileService: FileService,
    private campService: CampService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: {campId: string},
    private dialogRef: MatDialogRef<AddCampSequencyComponent>,
  ) {
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      title: ['', Validators.required],
      fileDate: [undefined, Validators.required],
      file: [null, Validators.required],
    });
  }

  get title() {
    return this.formGroup.get('title') as FormControl;
  }

  get fileDate() {
    return this.formGroup.get('fileDate') as FormControl;
  }

  get file() {
    return this.formGroup.get('file') as FormControl;
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target?.files[0];
      this.formGroup.patchValue({
        file: file
      });
    }
  }

  onSubmitFile() {

    if (this.formGroup.valid) {
      console.log('valid')
      this.fileService.uploadFile(this.file.value as File, DbKeys.camps ,this.contentType).then(
        (ref) => {
          ref.ref.getDownloadURL().then(
            (url) => {
              this.campService.addSequencyToCamp(this.data.campId, url, this.title.value, this.fileDate.value).then(
                () => {
                  this.snackBar.open('Le fichier a bien été uploadé', 'Fermer', {duration: 3000})

                },
                () => { this.snackBar.open('Une erreur est survenue lors de l\'upload du fichier', 'Fermer', {duration: 3000});}
              );
            },
            () => {this.snackBar.open('Une erreur est survenue lors de l\'upload du fichier', 'Fermer', {duration: 3000});}
          );

        },
        () => {},
      );
    }
  }
}

import { Injectable } from '@angular/core';
import {AngularFirestore, DocumentChangeAction, DocumentReference} from "@angular/fire/compat/firestore";

import {Observable} from "rxjs";
import {FileData} from "../../models/fileData";

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  private key = "results";

  constructor(private firestore: AngularFirestore) { }

  public addResult(data: FileData) : Promise<DocumentReference<unknown>>{
    return this.firestore
      .collection(this.key)
      .add({name: data.name, url: data.url});
  }


  public updateData(id: string, data: any) : Promise<void>{
    return new Promise<any>((resolve, reject) =>{
      this.firestore
        .collection(this.key)
        .doc(id)
        .update(data)
        .then(res => {}, err => reject(err));
    });
  }

  public deleteData(id?: string): Promise<void> {
    return this.firestore
      .collection(this.key)
      .doc(id)
      .delete();
  }


  public getdata() :  Observable<DocumentChangeAction<unknown>[]>{
    return this.firestore.collection(this.key).snapshotChanges();
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class DiaryComponent implements OnInit {
  isLoading: boolean;

  constructor() {
    this.isLoading = true;
  }

  ngOnInit(): void {
    this.isLoading = true;
  }

  load() {
    this.isLoading = false;
  }
}

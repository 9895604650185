import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TeamService} from "../../../services/db/team.service";
import {Location} from "@angular/common";
import {DatabaseService} from "../../../services/db/database.service";
import {DbKeys} from "../../../services/db/config/db-config";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-delete-team',
  templateUrl: './delete-team.component.html',
  styleUrls: ['./delete-team.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class DeleteTeamComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  private id: string;

  constructor(
    private route: ActivatedRoute,
    private db: DatabaseService,
    private location: Location,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
      this.id = params.get('id') || "";
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  delete() {
    this.db.deleteData(DbKeys.teams, this.id)
      .then(() => {
        this.snackbar.open('Equipe supprimée !', 'OK', {duration: 2000})
        this.location.back();
      })
      .catch(() => this.snackbar.open('Erreur lors de la suppression de l\'équipe', 'OK', {duration: 2000}));
  }

  back() {
    this.location.back();
  }

}

export class TournamentViewModel2 {
  playerId: string;
  playerName: string;
  averageScore: number;
  averageScoreString: string;
  totalScore: number;
  totalScoresString: string;
  scoresByDate: { [date: number]: number };

  constructor(playerName: string, scoresByDate: { [date: number]: number }) {
    this.playerName = playerName;
    this.scoresByDate = scoresByDate;
    this.calculateAverageScore();
  }

  calculateAverageScore() {
    const scoreValues = Object.values(this.scoresByDate);
    // Filter out scores that are 0
    const nonZeroScores = scoreValues.filter(score => score !== 0);
    const totalScore = nonZeroScores.reduce((acc, score) => acc + score, 0);
    this.averageScore = nonZeroScores.length > 0 ? totalScore / nonZeroScores.length : 0;
    this.averageScoreString = this.formatScore(this.averageScore);
  }

  calculateTotalScores() {
    this.totalScore = Object.values(this.scoresByDate).reduce((acc, score) => acc + score, 0);
    this.totalScoresString = this.formatScore(this.totalScore);
  }

  addScore(date: number, score: number) {
    this.scoresByDate[date] = Number(score);
    this.calculateAverageScore();
    this.calculateTotalScores();
  }

  removeScore(date: number) {
    delete this.scoresByDate[date];
    this.calculateAverageScore();
    this.calculateTotalScores();
  }

  getFormattedScore(date: number): string {
    const score = this.scoresByDate[date];
    if (score === undefined || score === null) {
      return '';
    }
    // Use Intl.NumberFormat for proper formatting
    return this.formatScore(score);
  }

  formatScore(score: number): string {
    return new Intl.NumberFormat('fr-FR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(score);
  }

}

import {IResult} from "./IResult";
import {DocumentChangeAction} from "@angular/fire/compat/firestore";

export interface ITeam {
  id?: string;
  name: string;
  captain: string;
  results: IResult[];


  getTotalScore(): number;
}

export class Team implements ITeam {
  id?: string;
  name: string;
  captain: string;
  results: IResult[];

  constructor(name: string, captain: string, results: IResult[], id?: string) {
    this.id = id;
    this.name = name;
    this.captain = captain;
    this.results = results;
  }

  getTotalScore(): number {
    return this.results.reduce((total, result) => total + result.score, 0);
  }

  static CreateFromDoc(data: DocumentChangeAction<ITeam>) {
    const id = data.payload.doc.id;
    const dataPayload = data.payload.doc.data();
    let results: IResult[] = dataPayload.results.map((result: any) => {
      return {
        id: result.id,
        score: result.score,
        date: result.date.toDate(),
        teamId: result.teamId
      }
    });
    return new Team(dataPayload.name, dataPayload.captain, results, id);
  }
}

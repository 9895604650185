import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CampService} from "../../../../services/db/camp.service";
import {Player} from "../../../../models/camp/player";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ICampResult, IScore} from "../../../../models/camp";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-camp-result-form',
  templateUrl: './camp-result-form.component.html',
  styleUrls: ['./camp-result-form.component.scss']
})
export class CampResultFormComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  private campId: string;
  players: Player[];

  formGroup = this.fb.group({
    date: ['', [Validators.required]],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {campId: string},
    private campService: CampService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private matDialogRef: MatDialogRef<CampResultFormComponent>
  ) { }

  ngOnInit(): void {
    this.campId = this.data.campId;

    this.campService.getCampPlayers(this.campId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
      this.players = data;

      this.formGroup = this.fb.group({
        date: ['', Validators.required],
      });
      this.addControls();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get date() {
    return this.formGroup.get('date') as FormControl;
  }

  //Method te create a form with a date and a score (number) input for each player
  addControls() {
    const formGroup = this.formGroup as unknown as FormGroup<{ [key: string]: AbstractControl<any, any>; }>;
    this.players.forEach(player => {
      if (player.id != null) {
        formGroup.addControl(player.id, this.fb.control(0));
      }
    });
  }

  onSubmitResults() {
    if (this.formGroup.valid) {
      //Get all scores and player ids in a IScore[] array
      const scores: IScore[] = [];
      this.players.forEach(player => {
        const playerId = player.id;
        if (playerId != null) {
          const score = this.formGroup.get(playerId)?.value || 0; // Initialize score to 0 if it is null or undefined
          scores.push({ playerId, score });
        }
      });
      let result = {
        date: this.date.value,
        scores: scores
      } as ICampResult

      this.campService.addCampResult(this.campId, result).then(
        () => {
          this.snackBar.open('Résultats ajoutés.', 'Ok', {
            duration: 3000,
          });
          this.matDialogRef.close(result);
        },
        () => {
          this.snackBar.open('Erreur lors de l\'ajout des résultats.', 'Ok', {
            duration: 3000,
          });
          this.matDialogRef.close();
        }
      );
    }
  }
}

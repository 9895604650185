import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {News} from "../../models/news";
import {DbKeys} from "./config/db-config";
import {DatabaseService} from "./database.service";

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private db : DatabaseService) { }

  setNewsOrder(news: News[]) {
    const promises = news.map((newsRow, index) => {
      newsRow.order = index;
      return this.db.updateData(newsRow.id || '', {order: index}, DbKeys.news)
    });
    return Promise.all(promises);
  }
}

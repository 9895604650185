import { Component, OnInit } from '@angular/core';
import {faPhone, faEnvelope, faLocationDot} from "@fortawesome/free-solid-svg-icons";



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class ContactComponent implements OnInit {

  faPhone = faPhone;
  faEnvelope = faEnvelope;
  faLocationDot = faLocationDot;

  constructor() { }

  ngOnInit(): void {
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {News} from "../../../models/news";
import {DatabaseService} from "../../../services/db/database.service";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Editor, Toolbar} from "ngx-editor";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {msWordDefaultColorPalette} from "../../rte/color-presets";
import {AuthentificationService} from "../../../services/authentification.service";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-cl-perron-rules',
  templateUrl: './cl-perron-rules.component.html',
  styleUrls: ['./cl-perron-rules.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class ClPerronRulesComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  htmlContent: string;
  form: FormGroup;
  editor: Editor;
  submited: boolean;
  edit = false;
  htmlText: string | null = ''
  safeHtml: SafeHtml

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  route = "clp-rules";
  dbPath = "clp-rules"
  colorPresets = msWordDefaultColorPalette;
  private docId: string;
  dataObject: { html: string };
  isLoggedIn: boolean;
  isEditMode = true;

  constructor(private db: DatabaseService,
              private activatedRoute: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private snackbar: MatSnackBar,
              private formBuilder: FormBuilder,
              private auth: AuthentificationService

  ) {
    this.form = this.formBuilder.group({
      html: new FormControl('', { validators: [Validators.required, Validators.minLength(1)] })
    });
    this.editor = new Editor();

  }

  ngOnInit(): void {
    //Use db.getData to get the first element in angular fire firestore and set it to this.form.get('html')
    this.db.getdata(this.dbPath)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
      if(data.length > 0) {
        this.dataObject = data[0].payload.doc.data() as {html: string};
        this.docId = data[0].payload.doc.id;
        this.form.get('html')?.setValue(this.dataObject.html);
        this.htmlText = this.dataObject.html;
        this.isLoggedIn = this.auth.isLoggedIn();
      }
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onLoad() {
  }

  onSubmit() {
    this.submited = true;
    if(this.form.valid) {
      if(!this.docId) {
        this.db.addData({html: this.html}, this.dbPath).then(() => {
          this.snackbar.open("Infos enregistrées", "OK", {duration: 3000});
        }).catch((error) => {
          this.snackbar.open("Erreur lors de l'enregistrement", "OK", {duration: 3000});
        });
      } else {
        this.db.updateData(this.docId, {html: this.html}, this.dbPath).then(() => {
          this.snackbar.open("Infos enregistrées", "OK", {duration: 3000});
        }).catch((error) => {
          this.snackbar.open("Erreur lors de l'enregistrement", "OK", {duration: 3000});
        });
      }
    }
  }

  get html() {
    return this.form.get('html')?.value;
  }

  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }
}

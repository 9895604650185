import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {FileService} from "../../../services/file/file.service";
import {DatabaseService} from "../../../services/db/database.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ContentTypes} from "../../../services/db/config/content-types";
import {DbKeys} from "../../../services/db/config/db-config";
import {ICamp} from "../../../models/camp";

@Component({
  selector: 'app-ajouter-sequence',
  templateUrl: './ajouter-sequence.component.html',
  styleUrls: ['./ajouter-sequence.component.scss']
})
export class AjouterSequenceComponent implements OnInit {


  form = this.fb.group({
    date: [''],
    pdfFile: [undefined]
  });
  private selectedFile: any;
  submitted: boolean;
  ContentTypes = ContentTypes;
  DbKeys = DbKeys;
  pdfTitle = "Ajouter une Séquence";
  pdfPlaceholder= "Nom/Lieux";

  constructor(
    private fb: FormBuilder,
    private fileService: FileService,
    private db: DatabaseService,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<AjouterSequenceComponent>,
  ) {
    this.form = this.fb.group({
      date: ['', Validators.required],
      pdfFile: [undefined, Validators.required]
    });
    this.submitted = false;
  }

  ngOnInit(): void {
  }



}






import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DatabaseService} from "../../../services/db/database.service";
import {DbKeys} from "../../../services/db/config/db-config";
import {ICampSequency} from "../../../models/camp";
import {FileService} from "../../../services/file/file.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-edit-camp-sequencies',
  templateUrl: './delete-camp-sequencies.component.html',
  styleUrls: ['./delete-camp-sequencies.component.scss']
})
export class DeleteCampSequencies implements OnInit {
  seq: ICampSequency;
  destroy$ = new Subject<boolean>();


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {sequency: ICampSequency},
    private dialogRef: MatDialogRef<DeleteCampSequencies>,
    private db: DatabaseService,
    private fileService: FileService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.seq = this.data.sequency;

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  deleteSequency() {
    console.log(this.seq)
    this.db.deleteData(DbKeys.campSequencies, this.seq.id).then(
      () => {
        this.fileService.deleteFile(this.seq.url)
        .pipe(takeUntil(this.destroy$))
          .subscribe({
          next: () => {
            this.snackbar.open("Fichier supprimé", "Fermer", {
              duration: 2000
            });
          },
          error: () => {

          },
          complete: () => {}
        });
        this.dialogRef.close(true);
      },
      () => {
        this.dialogRef.close(false);
      }
    );
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {DynamicTitleService} from "../../../services/db/dynamic-title.service";
import {Router} from "@angular/router";
import {AuthentificationService} from "../../../services/authentification.service";
import {BehaviorSubject} from "rxjs";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-dynamic-title',
  templateUrl: './dynamic-title.component.html',
  styleUrls: ['./dynamic-title.component.scss']
})
export class DynamicTitleComponent implements OnInit {
  @Input() routeInput: string;

  title: string;
  isAuthenticated$: BehaviorSubject<boolean>;
  form: FormGroup;

  constructor(
    private router: Router,
    private titleService: DynamicTitleService,
    private authService: AuthentificationService,
    private formBuilder: FormBuilder
  ) {
    this.title = "";
    this.form = this.formBuilder.group({
      title: [this.title]
    });
  }

  async ngOnInit(): Promise<void> {
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    await this.initTitle();

  }

  private async initTitle() {
    try {
      const route = this.routeInput || this.router.url;
      const data = await this.titleService.getTitleByRoute(route);
      this.title = data?.title ?? "";
      this.titleControl.setValue(this.title);
    } catch (error) {
      console.error('Failed to fetch title', error);
      this.title = "Pas de titre";
      this.titleControl.setValue(this.title);
    }
  }

  get titleControl() {
    return this.form.get('title') as FormControl;
  }

  changeTitle() {
    this.titleService.saveTitleByRoute(this.routeInput, this.titleControl.value)
      .then(() => console.log('Title saved'))
      .catch(error => console.error('Failed to save title', error))
      .finally(() => this.initTitle());
  }
}

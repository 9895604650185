import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monday-challenges',
  templateUrl: './monday-challenges.component.html',
  styleUrls: ['./monday-challenges.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class MondayChallengesComponent implements OnInit {
  isLoading: boolean;

  constructor() {
    this.isLoading = true;
  }

  ngOnInit(): void {
  }

  load() {
    this.isLoading = false;
  }
}

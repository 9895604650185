import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {MatDialog} from "@angular/material/dialog";
import {CampPlayerFormComponent} from "../camp-player-form/camp-player-form.component";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import {DeleteCampPlayerComponent} from "../delete-camp-player/delete-camp-player.component";
import {AuthentificationService} from "../../../../services/authentification.service";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {DatabaseService} from "../../../../services/db/database.service";
import {DbKeys} from "../../../../services/db/config/db-config";
import {CampService} from "../../../../services/db/camp.service";

@Component({
  selector: 'app-view-camp-players',
  templateUrl: './view-camp-players.component.html',
  styleUrls: ['./view-camp-players.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class ViewCampPlayersComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  @Input() campId: string;

  faPlus = faPlus;
  faTrash = faTrash;
  faPenToSquare = faPenToSquare;
  players: Player[];
  isLoggedIn$: BehaviorSubject<boolean>;

  arrayRoute$ = new BehaviorSubject<string>("");

  path: string;

  constructor(
    private authService: AuthentificationService,
    private route: ActivatedRoute,
    private db: DatabaseService,
    private dialog: MatDialog,
    private campService: CampService
  ) {
  }

  ngOnInit(): void {

    this.route.url
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.path = (val[0].path ?? "") + (this.campId ?? "");

        this.arrayRoute$.next(this.path);
      });

    this.campService.getCampPlayers(this.campId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(players => {
        this.players = players;
      });

    /*this.arrayRoute$
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        console.log(val)
      this.db.getById(DbKeys.playerNames, val)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          let data = result.payload.data() as any;

          if(data.playerNames !== null && data.playerNames !== undefined) {
            let playerNames = data.playerNames as string[];

            //Map to split Nom and prenom if possible otherwise nom == the name and prenom == ""
            this.players = playerNames.map((name) => {
              let split = name.split(" ");
              return {
                id: name,
                lastName: split[0] ?? "",
                firstName: split[1] ?? ""
              }
            }).sort((a, b) => a.lastName.localeCompare(b.lastName));
          }
        })
    });*/

    this.isLoggedIn$ = this.authService.isAuthenticated$;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  columns = [
    {name: 'Prénom', prop: 'firstName'},
    {name: 'Nom', prop: 'lastName'},
  ];

  openAdd() {
    let dialog = this.dialog.open(CampPlayerFormComponent, {
      data: {campId: this.campId},
    });

    dialog.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  openEdit(player: any): void {
    let dialog = this.dialog.open(CampPlayerFormComponent, {
      data: {
        campId: this.campId,
        player: player
      },
    });

    dialog.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  openDelete(player: any): void {
    let dialog = this.dialog.open(DeleteCampPlayerComponent, {
      data: {id: player.id},
    });

    dialog.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }
}

interface Player {
  firstName: string;
  lastName: string;
}

import { Injectable } from '@angular/core';
import {
  Action,
  AngularFirestore,
  DocumentChangeAction,
  DocumentReference,
  DocumentSnapshot
} from "@angular/fire/compat/firestore";
import {Tournament, TournamentImpl} from "../../models/tournament";
import {filter, from, map, mergeMap, Observable, ObservableInput, tap, toArray} from "rxjs";
import {DbKeys} from "./config/db-config";
import {serverTimestamp} from "@angular/fire/database";
import {Registration} from "../../models/registration";

@Injectable({
  providedIn: 'root'
})
export class TournamentService {

  constructor(private firestore: AngularFirestore) {

  }

  public addTournament(data: Tournament) : Promise<DocumentReference<unknown>>{
      return this.firestore
        .collection(DbKeys.tournaments)
        .add({lieu: data.lieu, date: data.date});
  }

  public updateData(id: string, data: any) : Promise<void>{
    return this.firestore
        .collection(DbKeys.tournaments)
        .doc(id)
        .update(data);
  }

  public deleteData(id: string): Promise<void> {
    return this.firestore
        .collection(DbKeys.tournaments)
        .doc(id)
        .delete();
  }

  public getdata() :  Observable<DocumentChangeAction<unknown>[]>{
    return this.firestore.collection(DbKeys.tournaments, ref =>
      ref.where('date', '>=', serverTimestamp())
    ).snapshotChanges();
  }

  public getById(id: string): Observable<Action<DocumentSnapshot<unknown>>> {
    return this.firestore
      .collection(DbKeys.tournaments)
      .doc(id)
      .snapshotChanges();
  }

  getTournaments(): Observable<Tournament[]> {
    return this.fetchTournaments().pipe(
      mergeMap(tournaments => from(tournaments)),
      filter(this.filterTournamentsByDate),
      mergeMap(tournament => this.fetchAndAssignRegistrationCount(tournament)),
      toArray()
    );
  }

  private fetchTournaments(): Observable<Tournament[]> {
    return this.firestore.collection(DbKeys.tournaments).get().pipe(
      map(snapshot => snapshot.docs.map(this.convertTournamentData))
    );
  }

  private convertTournamentData(doc: any): TournamentImpl {
    const tournament = doc.data() as Tournament;
    tournament.id = doc.id;
    tournament.date = tournament.date.toDate(); // Transform timestamp to Date
    return tournament as TournamentImpl;
  }

  private filterTournamentsByDate(tournament: Tournament): boolean {
    const todayAtMidnight = new Date();
    todayAtMidnight.setHours(0, 0, 0, 0);
    return tournament.date >= todayAtMidnight;
  }

  private fetchAndAssignRegistrationCount(tournament: Tournament): Observable<Tournament> {
    return this.firestore.collection(DbKeys.registrations, ref => ref.where('tournamentId', '==', tournament.id)).get().pipe(
      map(snapshot => {
        const registrations = snapshot.docs.map(doc => doc.data() as Registration);
        tournament.registrationCount = registrations.filter(reg => !reg.isReserve).length;
        tournament.reserveRegistrationCount = registrations.filter(reg => reg.isReserve).length;
        return tournament;
      })
    );
  }
}


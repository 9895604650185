import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ICamp} from "../../../models/camp";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {CampService} from "../../../services/db/camp.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService} from "primeng/api";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "../../utils/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'app-edit-camp',
  templateUrl: './edit-camp.component.html',
  styleUrls: ['./edit-camp.component.scss'],
  host: {'class': 'base-host-center styled-background'},
})
export class EditCampComponent implements OnInit {
  @Input() camp: ICamp;
  formGroup = this.fb.group({
    name: ['', Validators.required],
    password: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private campService: CampService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      name: [this.camp.name, Validators.required],
      password: [this.camp.password, Validators.required]
    });
  }

  supprimer() {
    if(this.camp.id) {
      this.campService.deleteCampAndRelatedData(this.camp.id)
        .then(
          () => {
            this.snackbar.open('Le stage a bien été supprimé !', 'Fermer', {
              duration: 5000,
            });
          },
          () => {
            this.snackbar.open('Une erreur est survenue lors de la suppression du stage !', 'Fermer', {
              duration: 5000,
            });
          },
        );
    }
    else {
      this.snackbar.open('Une erreur est survenue !', 'Fermer', {
        duration: 5000,
      });
    }
  }

  supprimer2() {
    //Same but uses a
  }

  get name() {
    return this.formGroup.get('name') as FormControl;
  }

  get password() {
    return this.formGroup.get('password') as FormControl;
  }

  onSubmit() {
    if(this.formGroup.valid && this.camp.id) {
      this.campService.setCampNameAndPassword(this.camp.id, this.name.value, this.password.value)
        .then(
          () => {
            this.snackbar.open('Le stage a bien été modifié !', 'Fermer', {
              duration: 5000,
            });
            this.camp.name = this.name.value;
            this.camp.password = this.password.value;
          },
          () => {
            this.snackbar.open('Une erreur est survenue lors de la modification du stage !', 'Fermer', {
              duration: 5000,
            });
          },
        );
    }

    if(!this.camp.id) {
      this.snackbar.open('Une erreur est survenue !', 'Fermer', {
        duration: 5000,
      });
    }
  }

  confirm($event: MouseEvent) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: {
        title: `Supprimer ${this.camp.name} ?`,
        message: `Êtes vous sûr de vouloir supprimer ${this.camp.name} ?`,
        acceptButton: 'Supprimer',
        rejectButton: 'Annuler',
        onAccept: () => {
          this.supprimer();
        }
      }
    });
  }
}

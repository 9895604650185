import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CampService} from "../../../services/db/camp.service";
import {Camp, ICamp} from "../../../models/camp";
import {AuthentificationService} from "../../../services/authentification.service";
import {BehaviorSubject, Subject, switchMap, takeUntil} from "rxjs";
import { ContentTypes } from 'src/app/services/db/config/content-types';
import { DbKeys } from 'src/app/services/db/config/db-config';
import {MatTabGroup} from "@angular/material/tabs";

@Component({
  selector: 'app-view-camp',
  templateUrl: './view-camp.component.html',
  styleUrls: ['./view-camp.component.scss'],
  host: {'class': 'base-host-center'}
})
export class ViewCampComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  authorised = false;
  camp: ICamp;
  isLoggedIn: BehaviorSubject<boolean>;

  pdfContentType = ContentTypes.pdf
  dbKey = DbKeys.campProgram

  constructor(
    private activatedRoute: ActivatedRoute,
    private campService: CampService,
    private authService: AuthentificationService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      takeUntil(this.destroy$),
      switchMap(params => {
        console.log("Route changed")
        const id = params['id'];
        // Fetch new data each time the route parameter changes
        return this.campService.getCampById(id);

      })
    ).subscribe(camp => {
      console.log("Subscribe")
      // Update your component state with the new data
      if (camp) {
        this.camp = camp;
      }
    });

    this.isLoggedIn = this.authService.isAuthenticated$;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onAccessNotify(authorized: boolean) {
    this.authorised = authorized;
  }
}

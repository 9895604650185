import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {MatDialog} from "@angular/material/dialog";
import {FileService} from "../../../../services/file/file.service";
import {AddPictureComponent} from "../add-picture/add-picture.component";
import {PicturesService} from "../../../../services/db/pictures.service";
import {IPicture} from "../../../../models/picture";
import {AuthentificationService} from "../../../../services/authentification.service";
import {BehaviorSubject, finalize, first, Subject, takeUntil} from "rxjs";
import {PrimeIcons} from "primeng/api";

@Component({
  selector: 'app-view-camp-pictures',
  templateUrl: './view-camp-pictures.component.html',
  styleUrls: ['./view-camp-pictures.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class ViewCampPicturesComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  @Input() campId: string;

  faPlus = faPlus;
  faTrash = faTrash;
  primeTrash = PrimeIcons.TRASH;
  primePlus = PrimeIcons.PLUS;
  pictures: IPicture;
  images: string[];
  isLoggedIn$: BehaviorSubject<boolean>;
  activeIndex = 0;
  numVisible: number = 3; // Default value
  containerStyle = { 'max-width': '640px' }; // Default style
  showPictures = false;

 responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5,
      numScroll: 3
    },
    {
      breakpoint: '768px',
      numVisible: 3,
      numScroll: 2
    },
    {
      breakpoint: '481px',
      numVisible: 2,
      numScroll: 1
    }
  ];

// Initialize variables as needed, e.g., images, activeIndex


  position: string = 'bottom';
  showIndicatorsOnItem: boolean = false;

  constructor(
    private matDialog: MatDialog,
    private pictureService: PicturesService,
    private authService: AuthentificationService,
    private fileService: FileService
  ) {
    this.images = [];
  }

  ngOnInit(): void {
    this.pictureService.getPicturesForEntity(this.campId)
    .pipe(takeUntil(this.destroy$))
      .subscribe(pictures => {
      if(pictures !== null && pictures !== undefined) {
        this.pictures = pictures;
        this.images = pictures.picturesUrl ?? [];

        if(pictures.picturesUrl.length > 0) {
          this.showPictures = true;
        }
      }
    });

    this.isLoggedIn$ = this.authService.isAuthenticated$
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  openAddPicture() {
    let dialogRef = this.matDialog.open(AddPictureComponent, {
      data: {campId: this.campId, images: this.images}
    });

    dialogRef.afterClosed().subscribe((result: {url: string, manuallyAdded: boolean}) => {
      if (result && !result.manuallyAdded) {
        const img = new Image();
        img.onload = () => {
          this.images.push(result.url);
        };
        img.src = result.url;
      }
    });
  }

  deletePicture() {
    if (!this.images.length) {
      console.warn('No images to delete');
      return;
    }

    const currentIndex = this.activeIndex;
    const currentUrl = this.images[currentIndex];

    this.fileService.deleteFile(currentUrl)
      .pipe(first(), finalize(() => {
        this.pictureService.removePictureForEntity(this.campId, currentUrl).then(() => {
          this.images.splice(currentIndex, 1); // Use splice to remove the item at the current index
          this.activeIndex = Math.max(0, currentIndex - 1); // Adjust the active index correctly
        });
      }))
      .subscribe({
        next: () => console.log('Picture deleted successfully'),
        error: () => console.error('Error deleting picture')
      });
  }


}

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'iframe-auto-sized',
  templateUrl: './iframe-auto-sized.component.html',
  styleUrls: ['./iframe-auto-sized.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class IframeAUtoSizedComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  @Input() url: string;
  @Input() title: string;
  @Output() isLoadingChange = new EventEmitter<boolean>();
  @ViewChild('iframe') iframe: ElementRef;
  height: number;
  width: number;
  tableContent: string;
  htmlContent: any;
  isLoading: boolean;

  constructor(private sanitizer: DomSanitizer, private http: HttpClient) {
  }


  ngOnInit() {
    this.isLoading = true;
    this.http.get(this.url, { responseType: 'text' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(html => {
      // Extract the gid parameter from the URL
      const gid = this.getParameterByName('gid', this.url);

      // Parse the HTML string into a DOM object
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      const gidElement = doc.getElementById(gid!);
      if (gidElement) {
        gidElement.style.display = '';
      }

      // Set the display: none style for elements with ids "top-bar" and "footer"
      const topBarElement = doc.getElementById('top-bar');
      if(topBarElement) {
        topBarElement!.style.display = 'none';
      }
      const footerElement = doc.getElementById('footer');
      if(footerElement)  {
        footerElement!.style.display = 'none';
      }

      // Remove all elements with the class row-headers-background
      const elementsToRemove = doc.querySelectorAll('.row-headers-background');
      elementsToRemove.forEach(element => element.remove());

      // Set the modified HTML content as the value of the htmlContent property
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(doc.documentElement.outerHTML);
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getParameterByName(name: string, url: string) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }


  ngAfterViewInit() {

  }

  onLoad() {

  }
}

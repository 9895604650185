import {Component, OnInit} from '@angular/core';
import {ContentTypes} from "../../../services/db/config/content-types";
import {DbKeys} from "../../../services/db/config/db-config";

@Component({
  selector: 'app-upload-html',
  templateUrl: './upload-html.component.html',
  styleUrls: ['./upload-html.component.scss'],
  host: {'class': 'base-host-center'}
})
export class UploadHtmlComponent implements OnInit {
  ContentTypes = ContentTypes;
  DbKeys = DbKeys;
  htmlTitle = "Ajouter un résultat";
  htmlPlaceholder = "Cercle";
  pdfTitle = "Ajouter une Séquence";
  pdfPlaceholder = "Cercle";


  constructor(
  ) { }


  ngOnInit(): void {

  }



}

import {DocumentChangeAction} from "@angular/fire/compat/firestore";

export interface FileData {
  name: string;
  date: any;
  url: string;
  id?: string;
}

export class FileDataFactory {
  static create(name: string, url: string, date: Date): FileData {
    return {name: name, url: url, date: date}
  }

  static createFromDoc(doc:  DocumentChangeAction<unknown>): FileData {
    const data = doc.payload.doc.data() as FileData;
    return {
      name: data.name,
      url: data.url,
      date: new Date(data.date.toDate()),
      id: doc.payload.doc.id}
  }
}



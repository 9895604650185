import {Injectable, OnDestroy} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenService implements OnDestroy{
  private destroy$ = new Subject<void>();

  isMobile: boolean;
  isMobileSubject = new BehaviorSubject<boolean>(false);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
      .pipe(takeUntil(this.destroy$))
      .subscribe(next => {
        this.isMobileSubject.next(next.matches)
      })

  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import {Component, Input, SecurityContext} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Editor, Toolbar} from 'ngx-editor';
import {DatabaseService} from "../../services/db/database.service";
import {DbKeys} from "../../services/db/config/db-config";
import {MatSnackBar} from "@angular/material/snack-bar";
import {News} from "../../models/news";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {msWordDefaultColorPalette} from "./color-presets";


@Component({
  selector: 'app-rte',
  templateUrl: './rte.component.html',
  styleUrls: ['./rte.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class RTEComponent {

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];



  form: FormGroup;
  editor: Editor;
  submited: boolean;
  edit = false;
  htmlText: string | null = ''
  safeHtml: SafeHtml

  @Input() news: News;
  @Input() title: string;

  @Input() route: string;
  @Input() dbPath: string;

  constructor(
    private formBuilder: FormBuilder,
    private db: DatabaseService,
    private snackbar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
    this.submited = false;
    this.editor = new Editor();
    this.form = this.formBuilder.group({
      html: new FormControl('', { validators: [Validators.required, Validators.minLength(1)] })
    });
  }

  //if news != undefined and edit = true, then we are in edit mode
  //we set the form value to the news html
  colorPresets = msWordDefaultColorPalette;
  ngOnInit(): void {
    if(!this.dbPath) {
      this.dbPath = DbKeys.news;
    }

    if(!this.route) {
      this.route = "infos";
    }

    if(this.news?.html) {
      console.log(this.news.html.toString())
      let test = this.sanitizer.sanitize(SecurityContext.HTML, this.news.html);
      this.htmlText = test;
    }
    if(!this.title) {
      this.title = "Ajouter une info";
    }
  }

  get html() {
    return this.form.get('html')?.value;
  }

  get newsForm() {
    return this.form;
  }


  onSubmit() {
    this.submited = true;
    if(this.newsForm.valid) {
      if(this.news?.id) {
        this.db.updateData(this.news.id, {html: this.html}, this.dbPath).then(
          () => {
            this.snackbar.open("News modifiée.", "Ok");
            this.router.navigate([this.route])
          },
          () => {this.snackbar.open("Erreur lors de la modification de la news.", "Compris")}
        )
      } else {
        this.db.addData({html: this.html}, this.dbPath).then(
          ()=>{
            this.snackbar.open("News ajoutée.", "Ok")
            this.router.navigate([this.route])
          },
          ()=>{this.snackbar.open("Erreur lors de l'ajout de la news.", "Compris")}
        );
      }

    }
    // retrieve the rich text from the form control using the getter

  }
}

import { Component, OnInit } from '@angular/core';
import {AuthentificationService} from "../../services/authentification.service";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  faGear = faGear;
  faRightFromBracket = faRightFromBracket;

  constructor(
    private authService: AuthentificationService,
    private router: Router) {

  }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logOut().then(r => console.log("Logged out."));
  }

  get isLoggedIn() : boolean {
    return this.authService.isLoggedIn();
  }


  login() {
    const currentUrl = this.router.url;
    this.router.navigate(['/login', { previousRoute: currentUrl }]);
  }
}

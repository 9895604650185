import {Component, Inject, Input, OnInit} from '@angular/core';
import {StringUtils} from "../../../../models/utils/string-utils";
import {AuthentificationService} from "../../../../services/authentification.service";
import {CampHtmlResult, CampService} from "../../../../services/db/camp.service";
import {ICamp} from "../../../../models/camp";
import {faTrash, faPlus} from "@fortawesome/free-solid-svg-icons";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {UploadFileFormComponent} from "../../../result/upload-file-form/upload-file-form.component";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-camp-html-results',
  templateUrl: './camp-html-results.component.html',
  styleUrls: ['./camp-html-results.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class CampHtmlResultsComponent implements OnInit {
  getDateSmallFormat = StringUtils.getDateSmallFormat;
  isLoggedIn: any;
  results: CampHtmlResult[] = [];
  faTrash = faTrash;
  faPlus = faPlus;
  @Input() camp: ICamp;

  constructor(
    private auth: AuthentificationService,
    private campService: CampService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private confirmation: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    console.log(
      "CampHtmlResultsComponent.ngOnInit",
      this.camp
    )
    this.isLoggedIn = this.auth.isAuthenticated$;
    this.campService.getCampResults(this.camp.id || '').then(
      (data) => {
        this.results = data;
      },
      (error: any) => {
      }
    );
  }

  delete(result: CampHtmlResult) {
    this.confirmation.confirm({
      message: `Voulez-vous vraiment supprimer le résultat ${result.name} - ${StringUtils.getDateSmallFormat(result.date)} ?`,
      accept: () => {
        this.campService.deleteCampResultHTML(result.id || '').then(
          () => {
            this.snackBar.open('Résultat supprimé', 'Fermer', {
              duration: 3000
            });
            this.results = this.results.filter((res: CampHtmlResult) => res.id !== result.id);
          },
          () => {
            this.snackBar.open('Erreur lors de la suppression', 'Fermer', {
              duration: 3000
            });
          }
        );
      },
      acceptLabel: "Oui",
      rejectLabel: "Non",
      acceptButtonStyleClass: "p-button-rounded p-button-outlined p-button-danger",
      rejectButtonStyleClass: "p-button-rounded p-button-outlined"
    });
  }

  openAdd() {


    let dialogRef = this.matDialog.open(UploadFileFormComponent, {
      data: {
        camp: this.camp
      }
    });


    dialogRef.afterClosed().subscribe((result: CampHtmlResult) => {
      if(result) {
        this.results.push(result);
        this.results.sort((a, b) => (a.date > b.date) ? 1 : -1);
      }
    });
  }
}

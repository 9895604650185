import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coupe-clp',
  templateUrl: './coupe-clp.component.html',
  styleUrls: ['./coupe-clp.component.scss']
})
export class CoupeClpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

export const msWordDefaultColorPalette: string[] = [
  "#000000", // Black
  "#FFFFFF", // White
  "#1F497D", // Dark Blue
  "#EEECE1", // Light Gray
  "#4F81BD", // Blue
  "#C0504D", // Red
  "#9BBB59", // Green
  "#8064A2", // Purple
  "#4BACC6", // Cyan
  "#F79646", // Orange
  "#C00000", // Dark Red
  "#000080", // Navy Blue
  "#FFC000", // Gold
  "#FFFF00", // Yellow
  "#92D050", // Lime
  "#00B050", // Dark Green
  "#00B0F0", // Light Blue
  "#0070C0", // Royal Blue
  "#002060", // Indigo
  "#7030A0", // Plum
  "#FF0000", // Red
  "#00FF00", // Green
  "#0000FF", // Blue
  "#FF00FF", // Magenta

];

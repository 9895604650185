import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ICamp, ICampResult} from "../../../../models/camp";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CampService} from "../../../../services/db/camp.service";

@Component({
  selector: 'app-delete-camp-result',
  templateUrl: './delete-camp-result.component.html',
  styleUrls: ['./delete-camp-result.component.scss']
})
export class DeleteCampResultComponent implements OnInit {
  resultForDate: ICampResult;
  private campId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {campId: string, result:  ICampResult},
    private dialogRef: MatDialogRef<DeleteCampResultComponent>,
    private snackbar: MatSnackBar,
    private campService: CampService
  ) { }

  ngOnInit(): void {
    this.campId = this.data.campId;
    this.resultForDate = this.data.result;
    console.log(`${this.resultForDate.date} ${this.campId}`)
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  deleteResult() {
    this.campService.deleteCampResult(this.campId, this.resultForDate).then(
      () => {
        this.snackbar.open("Résultat supprimé", "Fermer", {duration: 3000})
        this.dialogRef.close(true);
      },
      () => {
        this.snackbar.open("Erreur lors de la suppression du résultat", "Fermer", {duration: 3000})
        this.dialogRef.close(false);
      }
    );
    this.dialogRef.close(true);
  }

  formatDate(date: Date): string {
    return date.getDate() + "/" + (date.getMonth() + 1);
  }
}

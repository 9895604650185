import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monday-champane',
  templateUrl: './monday-champagne.component.html',
  styleUrls: ['./monday-champagne.component.scss'],
  host: {'class': 'styled-background base-host-center'}
})
export class MondayChampagneComponent implements OnInit {
  isLoading: boolean;

  constructor() {
    this.isLoading = true;
  }

  load() {
    this.isLoading = false;
  }

  ngOnInit(): void {
  }

}

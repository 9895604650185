import {Injectable} from '@angular/core';
import {
  Action,
  AngularFirestore,
  DocumentChangeAction,
  DocumentReference,
  DocumentSnapshot
} from "@angular/fire/compat/firestore";
import {Registration} from "../../models/registration";
import {Observable} from "rxjs";
import {DbKeys} from "./config/db-config";


@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private firestore: AngularFirestore) {
  }

  public addRegistration(data: Registration): Promise<DocumentReference<unknown>> {
    return this.firestore
      .collection(DbKeys.registrations)
      .add(data);
  }

  public updateData(id: string, data: any): Promise<void> {

    return this.firestore
      .collection(DbKeys.registrations)
      .doc(id)
      .update(data);
  }

  public deleteData(id: string): Promise<void> {
    return this.firestore
      .collection(DbKeys.registrations)
      .doc(id)
      .delete();
  }

  public getdata(): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore.collection(DbKeys.registrations).snapshotChanges();
  }

  public getById(id: string): Observable<Action<DocumentSnapshot<unknown>>> {
    return this.firestore
      .collection(DbKeys.registrations)
      .doc(id)
      .snapshotChanges();
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {TeamService} from "../../../services/db/team.service";
import {ITeam} from "../../../models/ITeam";
import {ITeamScore} from "../../../models/IResultViewModel";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Subject, Subscription, takeUntil} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-update-team-result',
  templateUrl: './update-team-result.component.html',
  styleUrls: ['./update-team-result.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class UpdateTeamResultComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  private teams: ITeam[];
  teamScores: ITeamScore[] = [];
  allDates: Date[] = [];
  selectedDate: Date;
  resultsForDate: ITeamScore[];
  formGroup: FormGroup;


  constructor(
    private teamService: TeamService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.formGroup = this.fb.group({});
  }

  ngOnInit(): void {


    this.teamService.getTeams()
      .pipe(takeUntil(this.destroy$))
      .subscribe(teams => {
        this.teams = teams;

        //Add controls only if they don't exist
        this.teams.forEach(team => {
          if (!this.formGroup.contains(team.id || '')) {
            this.formGroup.addControl(team.id || '', this.fb.control(0));
          }
        });

        this.teamScores = [];
        //Gets all dates in an array
        this.teams.forEach(team => {
          team.results.forEach(result => {

            this.allDates.push(result.date);

            let teamScore = {date: result.date, team: team, score: result.score} as ITeamScore;
            this.teamScores.push(teamScore);
          });
        });
        //Keep only dates that have different formatDate(date)
        this.allDates = this.allDates.filter((date, index, self) =>
          index === self.findIndex((t) => (
            t.getDate() === date.getDate() && t.getMonth() === date.getMonth()
          )));

      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  //Format date jj/MM

  formatDate(date: Date): string {
    return date.getDate() + "/" + (date.getMonth() + 1);
  }

  onChangeDate(): void {
    this.resultsForDate = this.teamScores.filter(teamScore => this.formatDate(teamScore.date) === this.formatDate(this.selectedDate));


    this.resultsForDate.forEach(teamScore => {
      //Set value based on team.id
      this.formGroup.get(teamScore.team.id || '')?.setValue(teamScore.score);
      //Reflect changes in IHM

    });
  }

  onSumbitResults() {


    if (this.formGroup.valid) {
      let scoreViewModels = this.teams.map(team => {
        //Get value with team.id from formControls
        let score = this.formGroup.get(team.id || '')?.value;
        return {date: this.selectedDate, score: score, team: team} as ITeamScore;
      });
      this.teamService.updateScores(scoreViewModels).then(() => {

        },
        () => {
          console.log("Error updating scores")
        }
      );
    }
  }

  onDeleteResults() {
    this.router.navigate(['/delete-result'], {queryParams: {myDate: this.selectedDate.toISOString()}});
  }
}


import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, map, Subject, takeUntil, toArray} from "rxjs";
import {SupprimerSequenceComponent} from "../upprimer-sequence/supprimer-sequence.component";
import {DatabaseService} from "../../../services/db/database.service";
import {MatDialog} from "@angular/material/dialog";
import {AuthentificationService} from "../../../services/authentification.service";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-view-sequencies',
  templateUrl: './view-sequencies.component.html',
  styleUrls: ['./view-sequencies.component.scss']
})
export class ViewSequenciesComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  sequencies: Sequency[];
  isAuthenticated$: BehaviorSubject<boolean>;
  faTrash = faTrash;

  constructor(
    private db: DatabaseService,
    private dialog: MatDialog,
    private auth: AuthentificationService,
  ) { }

  ngOnInit(): void {
    this.db.getdata('clp-sequences').pipe(
      map(data => data.map(e => ({
        id: e.payload.doc.id,
        date: e.payload.doc.get("date").toDate(), // Convert Firestore Timestamp to Date object if necessary
        name: e.payload.doc.get("name"),
        url: e.payload.doc.get("url"),
      }))),
      map(data => data.sort((a, b) => a.date.getTime() - b.date.getTime())),
      takeUntil(this.destroy$),
    ).subscribe((processedData) => {
      console.log("TEST")
      this.sequencies = processedData;
      //let date = this.sequencies.date : string (dd/MM/yyyy)
      console.log(this.sequencies)

    });


    this.isAuthenticated$ = this.auth.isAuthenticated$;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  openDelete(id: string) {
    let sequency = this.sequencies.find(s => s.id === id);
    this.dialog.open(SupprimerSequenceComponent, {
      data: {
        sequency: sequency
      }
    });
  }


  dateToString(date: Date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;

  }

}

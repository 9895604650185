import {DocumentChangeAction} from "@angular/fire/compat/firestore";

export interface ICamp {
  id?: string;
  name: string;
  password: string;

  results?: ICampResult[];

  getDates(): Date[];

}

export interface ICampResult {
  id?: string;
  date: Date;
  scores?: IScore[];
}

export class ICampResultImpl implements ICampResult {
  date: Date;
  id: string;
  scores: IScore[];
}

export interface IScore {
  playerId: string;
  score: number;
}

export class Camp implements ICamp{
  id?: string;
  name: string;
  password: string;

  results?: ICampResult[];

  public getDates(): Date[] {
    return this.results?.map(result => result.date) || [];
  }

  constructor(name: string, password: string, results: ICampResult[], id?: string) {
    this.id = id;
    this.name = name;
    this.password = password;

    this.results = results;
  }

  static createFromDocWithResults(data: DocumentChangeAction<ICamp>) {
    const id = data.payload.doc.id;
    const dataPayload = data.payload.doc.data();
    const camp = new Camp(dataPayload.name, dataPayload.password, dataPayload.results|| [], id);
    camp.results = dataPayload.results as ICampResult[];
    return camp;
  }

  static CreateFromDoc(data: DocumentChangeAction<ICamp>) {
    const id = data.payload.doc.id;
    const dataPayload = data.payload.doc.data();
    return new Camp(dataPayload.name, dataPayload.password, dataPayload.results || [], id);
  }
}

export interface ICampSequency {
  id: string;
  campId: string;
  title: string;
  url: string;
  fileDate: Date;
}

export interface CampProgram {
  id?: string;
  campId: string;
  url: string;
}

import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TeamService} from "../../../services/db/team.service";
import {ITeam} from "../../../models/ITeam";
import {DatatableComponent, TableColumn} from "@swimlane/ngx-datatable";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import {AuthentificationService} from "../../../services/authentification.service";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {AjouterSequenceComponent} from "../ajouter-sequence/ajouter-sequence.component";
import {DatabaseService} from "../../../services/db/database.service";
import {ViewSequenciesComponent} from "../view-sequencies/view-sequencies.component";
import {ActivatedRoute} from "@angular/router";



@Component({
  selector: 'app-view-team-results',
  templateUrl: './view-team-results.component.html',
  styleUrls: ['./view-team-results.component.scss'],
  host: {'class': 'styled-background'}
})
export class ViewTeamResultsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tableReference') table: DatatableComponent;
  destroy$ = new Subject<boolean>();

  teams: ITeam[];
  rows: any[];
  columns: TableColumn[];
  faPenToSquare = faPenToSquare;
  faTrash = faTrash;
  faPlus = faPlus
  isLoggedIn: boolean;
  isAuthenticated$: BehaviorSubject<boolean>;
  arrayRoute$ = new BehaviorSubject<string>("")

  constructor(
    private teamService: TeamService,
    private auth: AuthentificationService,
    private dialog: MatDialog,
    private db: DatabaseService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.auth.isLoggedIn();
    this.isAuthenticated$ = this.auth.isAuthenticated$;
    //this.teamService.removeAllResults().then(() =>
    this.teamService.getTeams()
      .pipe(takeUntil(this.destroy$))
      .subscribe(teams => {
      console.log(teams)
      this.teams = teams;
      this.rows = this.getRows();
      this.columns = this.getColumns();
    });

    this.route.url
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.arrayRoute$.next( val[0].path ?? "");
      });

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getRowClass(row: any, index: number) {
    return {
      'row-color1': row.gender === 'Male',
      'row-color2': row.gender === 'Female'
    };
  }

  ngAfterViewInit(): void {

  }


  getColumns(): TableColumn[] {
    if (!this.teams) {
      return [];
    }

    const dates: Date[] = [];
    this.teams.forEach((team) => {
      team.results.forEach((result) => {
        const date = new Date(result.date.getTime()); // create a new Date object for each unique date
        if (!dates.some((d) => d.getTime() === date.getTime())) { // check if the date already exists in the array
          dates.push(date);
        }
      });
    });

    const columns: TableColumn[] = [];
    //columns.push({ name: 'Team', prop: 'name' }); // add the first column manually
    dates.sort((a, b) => b.getTime() - a.getTime()).forEach((date) => {
      const name = this.getFormattedDate(date);
      if (name) {
        columns.push({
          name: name,
          prop: name
        });
      }
    });

    return columns;
  }

  private getRows(): any[] {
    if(!this.teams) {
      return [];
    }
    const map = this.teams.map((team, index) => {
      const row = { rank: index+1, name: team.name, total: team.getTotalScore().toFixed(2) };

      team.results.forEach((result) => {

        // @ts-ignore
        row[this.getFormattedDate(result.date)] = result.score.toFixed(2);
      });
      return row;
    });

    return map;
  }

  private getFormattedDate(date: Date) {
    return date.toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit'});
  }

  openDialog() {
    const dialogRef = this.dialog.open(AjouterSequenceComponent);
  }


  openSequencyDialog() {
    const dialogRef = this.dialog.open(ViewSequenciesComponent);
  }




}



// background.service.ts
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {
  constructor(private storage: AngularFireStorage) {}

  getBackgroundUrl(): Observable<string> {
    let month = new Date().getMonth() + 1;
    const filePath = `backgrounds/background-${month}.jpg`;
    const ref = this.storage.ref(filePath);
    return ref.getDownloadURL();
  }
}

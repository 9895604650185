import { Component, OnInit } from '@angular/core';
import { Tournament } from '../../../models/tournament';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatabaseService } from '../../../services/db/database.service';
import { DbKeys } from '../../../services/db/config/db-config';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-tournament-form',
  templateUrl: './new-tournament-form.component.html',
  styleUrls: ['./new-tournament-form.component.scss'],
  host: { class: 'base-host-center styled-background' },
})
export class NewTournamentFormComponent implements OnInit {
  tournamentForm = this.fb.group({
    lieu: ['', Validators.required],
    date: ['', Validators.required],
    maxRegistrations: [0, Validators.required],
    maxReserveRegistrations: [0, Validators.required],
  });
  private todayDateString: string;

  get date() {
    return this.tournamentForm.get('date');
  }

  get lieu() {
    return this.tournamentForm.get('lieu');
  }

  get maxRegistrations() {
    return this.tournamentForm.get('maxRegistrations');
  }

  get maxReserveRegistrations() {
    return this.tournamentForm.get('maxReserveRegistrations');
  }

  constructor(
    private db: DatabaseService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private location: Location
  ) {
    let todayDate = new Date();
    this.todayDateString = todayDate.toLocaleDateString();
  }

  ngOnInit(): void {
    let todayDate = new Date();
    this.todayDateString = todayDate.toLocaleDateString();

    this.tournamentForm = this.fb.group({
      lieu: ['', Validators.required],
      date: [this.todayDateString, Validators.required],
      maxRegistrations: [0, Validators.required],
      maxReserveRegistrations: [0, Validators.required],
    });
  }

  onSubmit(value: any) {

    let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    let dt = new Date(value.date.toString().replace(pattern, '$3-$2-$1'));

    this.db.addData(this.castToTournament(value, dt), DbKeys.tournaments).then(
      () => {
        this.snackbar.open('Tournoi ajouté', 'Fermer');
        this.tournamentForm.reset();
      },
      () => {
        this.snackbar.open("Erreur lors de l'ajout du tournoi.", 'Fermer');
      }
    );
  }

  private castToTournament(value: any, dt: Date) {
    return {
      lieu: value.lieu,
      date: dt,
      maxRegistrations: +value.maxRegistrations,
      registrationCount: 0,
      maxReserveRegistrations: +value.maxReserveRegistrations,
      reserveRegistrationCount: 0,
    } as Tournament;
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ContentTypes} from "../../../../services/db/config/content-types";
import {FileService} from "../../../../services/file/file.service";
import {DbKeys} from "../../../../services/db/config/db-config";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CampBid, CampService} from "../../../../services/db/camp.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-camp-bid-form',
  templateUrl: './camp-bid-form.component.html',
  styleUrls: ['./camp-bid-form.component.scss']
})
export class CampBidFOrmComponent implements OnInit {
  contentType = ContentTypes.pdf;
  formGroup = this.fb.group({
    name: ['', Validators.required],
    file: [undefined, Validators.required],
  });
  sumbitted = false;
  private campId: string;
  constructor(
    private fb: FormBuilder,
    private fileService: FileService,
    private snackbar: MatSnackBar,
    private campService: CampService,
    @Inject(MAT_DIALOG_DATA) public data: {campId: string},
  ) { }

  ngOnInit(): void {
    this.campId = this.data.campId;
    this.formGroup = this.fb.group(
      {
        name: ['', Validators.required],
        file: [undefined, Validators.required],
      }
    )
  }

  get file() {
    return this.formGroup.get('file') as FormControl;
  }

  get name() {
    return this.formGroup.get('name') as FormControl;
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target?.files[0];
      this.formGroup.patchValue({
        file: file
      });
    }
  }

  onSubmit() {

    this.sumbitted = true;
    if(this.formGroup.valid) {

      this.fileService.uploadFile(this.file.value as File, DbKeys.campBids, this.contentType)
        .then(
          (res) => {
            res.ref.getDownloadURL().then(
              (url) => {
                this.campService.addCampBid({campId: this.campId, url: url, name: this.name.value} as CampBid)
                  .then(
                    () => {
                      this.snackbar.open('Fichier envoyé', 'OK', {duration: 5000})
                    },
                    () => {
                      this.snackbar.open('Erreur lors de l\'envoi du fichier', 'OK', {duration: 5000})
                    }
                  )
              },
              () => {this.snackbar.open('Erreur lors de l\'envoi du fichier', 'OK', {duration: 5000})}
            )
          },
          () => {
            this.snackbar.open('Erreur lors de l\'envoi du fichier', 'OK', {duration: 5000})
          },
        );
    }
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DatabaseService} from "../../../services/db/database.service";
import {DbKeys} from "../../../services/db/config/db-config";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-supprimer-sequence',
  templateUrl: './supprimer-sequence.component.html',
  styleUrls: ['./supprimer-sequence.component.scss']
})
export class SupprimerSequenceComponent implements OnInit {
  private id: any;
  sequency: Sequency;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private db: DatabaseService,
    private dialogRef: MatDialogRef<SupprimerSequenceComponent>,
    private snackbar: MatSnackBar
  ) {
    this.sequency = this.data.sequency;

  }

  ngOnInit(): void {

  }

  closeDialog() {
    this.dialogRef.close();
  }

  deleteAndClose() {
    console.log(this.sequency.id)
    this.db.deleteData(DbKeys.clpSequences, this.sequency.id).then(
      () => {this.dialogRef.close()},
      () => {this.snackbar.open("Une erreur est survenue lors de la suppression de la séquence", "Fermer", {duration: 5000})}
    )

  }
}

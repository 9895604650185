import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CampService} from "../../../../services/db/camp.service";
import {Player} from "../../../../models/camp/player";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-delete-camp-player',
  templateUrl: './delete-camp-player.component.html',
  styleUrls: ['./delete-camp-player.component.scss']
})
export class DeleteCampPlayerComponent implements OnInit, OnDestroy {
   player: Player;
  destroy$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteCampPlayerComponent>,
    private campService: CampService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    console.log(this.data.id)
    this.campService.getCampPlayerById(this.data.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((player) => {
      this.player = player;

    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }



  closeDialog() {
    this.dialogRef.close()
  }

  deletePlayer() {
    this.campService.deleteCampPlayer(this.player.id || '').then(
      () => {
        this.snackbar.open('Joueur supprimé', 'Fermer', {
          duration: 3000
        });
        /*this.campService.deletePlayerScores(this.player.id || '', this.player.campId || '').then(
          () => {
            this.snackbar.open('Scores supprimés', 'Fermer', {
              duration: 3000
            });
            this.dialogRef.close(this.player.id);
          },
          (err) => {
            this.snackbar.open('Une erreur est survenue lors de la suppression des scores.', 'Fermer', {
              duration: 3000
            });

            console.log("PLAYER id : ")
            console.log(this.player.id)
            this.dialogRef.close(this.player.id);
          }
          );*/
      },
      () => {
        this.snackbar.open('Une erreur est survenue lors de la suppression du joueur.', 'Fermer', {
          duration: 3000
        });
        this.closeDialog();
      }
    );

  }
}

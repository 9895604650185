import {Component, Input, OnInit} from '@angular/core';
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-camp-plan',
  templateUrl: './camp-plan.component.html',
  styleUrls: ['./camp-plan.component.scss']
})
export class CampPlanComponent implements OnInit {
  @Input() campId: string;

  faPenToSquare = faPenToSquare;

  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";



@Injectable({
  providedIn: 'root'
})
export class SpreadsheetServiceService {

  constructor(private http: HttpClient) { }

  public getSpreadSheetCsv(url: string) {
    return this.http.get(url ,{responseType: "text"});
  }
  public csvToJson(csv: string, headers: Map<string, number>, excludedRows: number[]) {
    let data: {}[] = [];
    csv
      .split('\n')
      .forEach((line, index) => {
        let split = line.split(",")
        let row = {}
        headers.forEach((index, key) => {
          let value = split[index];
          Object.assign(row, {[key]: value});
        });
        if(!excludedRows.includes(index))
          data.push(row);
      });
    return data;
  }

}

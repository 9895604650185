import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './cl-perron-calendar.component.html',
  styleUrls: ['./cl-perron-calendar.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class ClPerronCalendarComponent implements OnInit {
  calendarUrl = "https://firebasestorage.googleapis.com/v0/b/bridgepro-d6489.appspot.com/o/images%2FBIDON_page-0001.jpg?alt=media&token=f5fb448e-50b4-48c8-b4de-4419a7a7ddf8";

  constructor() { }

  ngOnInit(): void {
  }

}

import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentReference
} from "@angular/fire/compat/firestore";
import {ITeam, Team} from "../../models/ITeam";
import {map, Observable} from "rxjs";
import {IResult} from "../../models/IResult";

import 'firebase/firestore';
import {ITeamScore} from "../../models/IResultViewModel";

@Injectable({
  providedIn: 'root'
})

export class TeamService {

  private teamsCollection: AngularFirestoreCollection<ITeam>;

  constructor(private afs: AngularFirestore) {
    this.teamsCollection = this.afs.collection<ITeam>('teams');
  }

  public addTeam(data: ITeam): Promise<DocumentReference<unknown>> {
    return this.teamsCollection.add(data);
  }

  getTeams(): Observable<ITeam[]> {
    return this.teamsCollection.snapshotChanges().pipe(
      map(actions =>
        actions.map(data => Team.CreateFromDoc(data))
      ),
      map(teams =>
        teams.sort((a, b) => b.getTotalScore() - a.getTotalScore())
      )
    );
  }

  public getTeamById(id: string): Observable<ITeam> {
    return this.teamsCollection.doc(id).snapshotChanges().pipe(
      map(action => {
        const data = action.payload.data() as ITeam;
        const id = action.payload.id;
        return {id, ...data};
      }),
    );
  }

  public updateTeam(id: string, data: any): Promise<void> {
    return this.teamsCollection.doc(id).update(data);
  }


  public addResultToTeam(result: IResult, teamId: string): Promise<void> {
    return this.teamsCollection.doc(teamId).ref.get().then(teamDoc => {
      const currentResults = teamDoc.get('results').map((result: { date: { toDate: () => any; }; score: any; }) => {
        return {
          date: result.date.toDate(),
          score: result.score
        } as IResult;
      });

      const resultExists = currentResults.some((existingResult: { date: Date; }) => {
          this.isSameDate(existingResult.date, result.date)
        }
      );
      if (resultExists) {
        return Promise.reject(new Error('A result with the same date already exists.'));
      } else {
        return this.teamsCollection.doc(teamId).update({
          results: [...currentResults, result]
        });
      }
    });
  }

  private isSameDate(date1: Date, date2: Date): boolean {
    console.log(date1)
    console.log(date2)
    return date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear();
  }


  //Remove all results from all teams
  public removeAllResults(): Promise<void> {
    console.log("Called5")
    return this.teamsCollection.ref.get().then(teams => {
      teams.forEach(team => {
        this.teamsCollection.doc(team.id).update({
          results: []
        });
      });
    });
  }


  /**
   * Update one result for each team
   * @param scoreViewModels
   */

  updateScores(scoreViewModels: ITeamScore[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    // Create an array of promises
    const promises = scoreViewModels.map(scoreViewModel => {
      const teamRef = this.afs.firestore.collection('teams').doc(scoreViewModel.team.id);

      return teamRef.get().then(teamDoc => {
        const results = teamDoc.get('results');
        const resultIndex = results.findIndex((result: any) => result.date.toDate().getTime() === scoreViewModel.date.getTime());

        // Update the result with the new score
        if (resultIndex !== -1) {
          results[resultIndex].score = scoreViewModel.score;
        }

        // Update the results array using batch
        batch.update(teamRef, {results});
      });
    });

    // Wait for all promises to resolve and then commit the batch
    return Promise.all(promises).then(() => batch.commit());
  }

  //Delete scores in the same pattern then previous method using promises and return promises.all commit the batch in promise all

  deleteScores(selectedDate: Date): Promise<void> {
    const batch = this.afs.firestore.batch();

    // Get teams and create an array of promises
    return this.teamsCollection.ref.get().then(teamsSnapshot => {
      const promises = teamsSnapshot.docs.map(teamDoc => {
        const teamRef = this.afs.firestore.collection('teams').doc(teamDoc.id);
        const results = teamDoc.get('results');
        const resultIndex = results.findIndex((result: any) => result.date.toDate().getTime() === selectedDate.getTime());

        if (resultIndex !== -1) {
          results.splice(resultIndex, 1);
        }

        // Update the results array using batch
        batch.update(teamRef, {results});

        // Return a resolved Promise for Promise.all() to work
        return Promise.resolve();
      });

      // Wait for all promises to resolve and then commit the batch
      return Promise.all(promises).then(() => batch.commit());
    });
  }
}



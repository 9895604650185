import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-competition-captains',
  templateUrl: './competition-captains.component.html',
  styleUrls: ['./competition-captains.component.scss']
})
export class CompetitionCaptainsComponent implements OnInit {
  isLoading: boolean;

  constructor() {
    this.isLoading = true;
  }

  ngOnInit(): void {
  }

  onLoad() {
    this.isLoading = false;
  }
}

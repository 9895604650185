import {Component, OnDestroy, OnInit} from '@angular/core';
import {CompetitionService} from "../../../../services/competition/competition.service";
import {Subject, takeUntil} from "rxjs";
import {Player} from "../../../../models/competition/player";
import {FormBuilder, FormControl} from "@angular/forms";
import {Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-view-players',
  templateUrl: './view-players.component.html',
  styleUrls: ['./view-players.component.scss'],
})
export class ViewPlayersComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  players: Player[] = [];
  value: any;
  formGroup = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(3)]]
  });

  disableButton: boolean;
  loading: any;
  editingPlayerId: any;
  private originalName  : string;

  constructor(
    private competitionService: CompetitionService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private confirmationService: ConfirmationService
  ) {
    this.disableButton = true;
    this.loading = true;
    this.editingPlayerId = "";
    this.originalName = "";
  }

  ngOnInit(): void {
    this.competitionService.getPlayers()
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {

        this.players = response.map(p => new Player(p.name, p.id))
          .sort((a, b) => a.name.localeCompare(b.name));
        this.loading = false;
      });

    this.formGroup.get('name')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(name => {

        this.disableButton = this.players.map(p => p.name)
          .includes(name as string) || !this.formGroup.valid;
      });


  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get name() {
    return this.formGroup.get('name') as FormControl;
  }

  /**
   * Attempts to add a new player based on form input.
   */
  addPlayer() {
    const name = this.formGroup.value.name?.trim();
    if (this.formGroup.valid && name && !this.playerExist(name)) {

      this.competitionService.createPlayer(new Player(name))
        .then(r => console.log(r))
        .catch(r => {
          this.snackbar.open("Erreur lors de l'ajout.", "OK", {duration: 2000});
        })
        .finally(() => this.resetForm())
    } else {

    }
  }

  /**
   * Checks if a player with the given name already exists.
   *
   * @param {string} name - The name of the player to check.
   * @returns {boolean} - True if the player exists, false otherwise.
   */
  playerExist(name: string) {
    return this.players.some(player => player.name === name);
  }

  resetForm() {
    this.formGroup.reset({
      name: '',
    });
  }

  editPlayer(player: Player) {
    this.editingPlayerId = player.id;
    this.originalName = player.name;
  }

  savePlayer(player: Player) {
    console.log("Save player :", player)
    this.competitionService.editPlayer(player)
      .then()
      .catch(error => {
        this.snackbar.open("Erreur lors de la sauvegarde", "OK", {duration: 2000});
      })
      .finally(() => this.editingPlayerId = "")
  }

  cancelEdit(player: Player) {
    player.name = this.originalName;
    this.originalName = "";
    this.editingPlayerId = "";
  }

  deletePlayer($event: MouseEvent, player: Player) {
    this.confirmationService.confirm({
      target: $event?.currentTarget as EventTarget | undefined,
      message: `Êtes vous sur de vouloir supprimer ${player.name} ?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.competitionService.deletePlayer(player)
          .then().catch().finally();
      },
      acceptLabel: "Oui",
      rejectLabel: "Non",
      acceptButtonStyleClass: "p-button-rounded p-button-outlined p-button-danger",
      rejectButtonStyleClass: "p-button-rounded p-button-outlined"
    });

  }

}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-camp-result',
  templateUrl: './camp-result.component.html',
  styleUrls: ['./camp-result.component.scss'],
  host: {'class': 'styled-background'}
})
export class CampResultComponent implements OnInit, OnChanges {
  destroy$ = new Subject<boolean>()
  arrayRoute$ = new BehaviorSubject<string>("")
  path: string;
  @Input() campId!: string | undefined;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.log(
      this.campId, "campId"
    )
    this.route.url
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.path = (val[0].path  ?? "") + (this.campId ?? "");

        this.arrayRoute$.next( this.path );
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['campId'] && this.campId) {
      this.path = (this.arrayRoute$.value ?? "") + this.campId;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {TournamentService} from "../../../services/db/tournament.service";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {Tournament, TournamentFactory} from "../../../models/tournament";
import {DatePipe} from "@angular/common";
import {DatabaseService} from "../../../services/db/database.service";
import {DbKeys} from "../../../services/db/config/db-config";
import {FormBuilder, Validators} from "@angular/forms";
import {Location} from "@angular/common";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-edit-tournament',
  templateUrl: './edit-tournament.component.html',
  styleUrls: ['./edit-tournament.component.scss'],
  host: {'class': 'base-host-center styled-background'},
})
export class EditTournamentComponent implements OnInit, OnDestroy {
  $lieu: any;
  $date: any;
  $maxRegistrations: any;
  messageColor: string;
  message: string;
  tournament: Tournament;
  private id: string | null;
  baseDate: Date;

  destroy$ = new Subject<boolean>();

  constructor(
    private tournamentService: TournamentService,
    private route: ActivatedRoute,
    private router: Router,
    private datepipe: DatePipe,
    private db: DatabaseService,
    private fb: FormBuilder,
    private location: Location
  ) {
    this.baseDate = new Date();


  }

  tournamentForm = this.fb.group({
    lieu: ["", Validators.required],
    date: ["", Validators.required],
    maxRegistrations: [0, Validators.required],
    maxReserveRegistrations: [0, Validators.required],
  });
  private todayDateString: string;
  get date(){
    return this.tournamentForm.get("date");
  }

  get lieu(){
    return this.tournamentForm.get("lieu");
  }

  get maxRegistrations() {
    return this.tournamentForm.get("maxRegistrations");
  }

  get maxReserveRegistrations() {
    return this.tournamentForm.get("maxReserveRegistrations");
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    if (this.id !== null) {
      this.db.getdata(DbKeys.tournaments)
        .pipe(takeUntil(this.destroy$))
        .subscribe(doc => {
        let tournament = doc.find(d => d.payload.doc.id === this.id);
        this.tournament = tournament?.payload.doc.data() as Tournament;

        const toDate = tournament?.payload.doc.get("date").toDate();
        this.tournamentForm.controls['date'].setValue(toDate);
        this.tournamentForm.controls['lieu'].setValue(tournament?.payload.doc.get("lieu"));
        this.tournamentForm.controls['maxRegistrations'].setValue(tournament?.payload.doc.get("maxRegistrations"));
        this.tournamentForm.controls['maxReserveRegistrations'].setValue(tournament?.payload.doc.get("maxReserveRegistrations"));

      });
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  goBack() {
    this.location.back();
  }

  updateTournament() {
    const formData = this.tournamentForm.value;
    const tournament: Tournament = {
      date: formData.date || '',
      lieu: formData.lieu || '',
      maxRegistrations: formData.maxRegistrations || 0,
      maxReserveRegistrations: formData.maxReserveRegistrations || 0,
      reserveRegistrationCount: 0,
      registrationCount: 0
    };
    this.tournamentService.updateData(this.id || '', tournament).then(() => {
      this.goBack();
    });
  }

  onSubmit(value: any) {

    this.updateTournament();


  }
}

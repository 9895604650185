import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CampService} from "../../../services/db/camp.service";
import {map, Subject, takeUntil, toArray} from "rxjs";
import {ICamp} from "../../../models/camp";
import {Router} from "@angular/router";

@Component({
  selector: 'app-camp-nav-list',
  templateUrl: './camp-nav-list.component.html',
  styleUrls: ['./camp-nav-list.component.scss'],
})
export class CampNavListComponent implements OnInit, OnDestroy {
  @Input() isMobile: boolean;
  destroy$ = new Subject<boolean>();

  camps: ICamp[];

  constructor(
    private campService: CampService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.campService.getCamps().pipe(
      takeUntil(this.destroy$),
      map(array => array.sort((a, b) => a.name.localeCompare(b.name)))
    ).subscribe(camps => {
      this.camps = camps;
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  navigate(id: string | undefined) {
    this.router.navigate(['/']).then(() => {
      this.router.navigate(['/view-camp', id]);
    })
  }
}

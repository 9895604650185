import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TournamentScoreViewModel } from '../../../../models/competition/tournament-score-view-model';
import { AuthentificationService } from '../../../../services/authentification.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import {
  faCalendar,
  faCalendarXmark,
} from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FormControl } from '@angular/forms';
import { Table } from 'primeng/table';
import {
  SortFunctionKey,
  TournamentManager,
} from '../../../../models/competition/tournament-manager';
import { FileService } from '../../../../services/file/file.service';
import { ContentTypes } from '../../../../services/db/config/content-types';
import { TournamentViewModel2 } from '../../../../models/competition/TournamentScoreViewModel2';
import {DatabaseService} from "../../../../services/db/database.service";
import {DbKeys} from "../../../../services/db/config/db-config";

// Assume DataService is available for fetching real data in production

@Component({
  selector: 'app-result-array',
  templateUrl: './result-array.component.html',
  styleUrls: ['./result-array.component.scss'],
})
export class ResultArrayComponent implements OnInit, OnDestroy {
  defaultDirectory = 'competition';

  @Input('arrayRoute') arrayRoute$: string | null;
  @Input() showAverageScore: boolean = false;
  @Input() showTotalScore: boolean = false;

  @Input('defaultSortFunction') defaultSortFunction: SortFunctionKey;
  @ViewChild('table') table: Table;

  destroy$: Subject<boolean> = new Subject<boolean>();
  isAuthenticated$: BehaviorSubject<boolean>;

  data: TournamentScoreViewModel[] = [];
  manager: TournamentManager;
  newDate: FormControl;

  isDeletingPlayer = false;
  isDeletingDates = false;

  constructor(
    private authService: AuthentificationService,
    private cdRef: ChangeDetectorRef,
    private fileService: FileService,
    private db: DatabaseService
  ) {
    this.newDate = new FormControl(new Date());
  }

  async ngOnInit(): Promise<void> {
    await this.getResults();
    this.isAuthenticated$ = this.authService.isAuthenticated$;

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /*private subscribeToDateChanges() {
    this.newDate.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Date) => {
        this.addNewDate(value);
        this.cdRef.detectChanges();
      });
  }*/

  pickerClosed() {
    if(this.newDate.value) {
      this.addNewDate(this.newDate.value);
      this.cdRef.detectChanges();
    }
  }

  pickerOpened() {
    this.newDate.reset(null);
  }

  /*private formatDate(value: Date) {
    return value.toLocaleString('fr-FR', { month: '2-digit', day: '2-digit' });
  }*/

  private addNewDate(date: Date): void {
    const timestamp = date.getTime();
    this.updateDataWithNewDate(timestamp);
  }

  private updateDataWithNewDate(timestamp: number): void {
    this.manager.addNewDate(timestamp);
    this.manager.updateAverages();
    //this.table.reset();
  }

  icons = {
    faCalendarXmark,
    faCalendar,
    faXmark,
  };

  iconStyles = {
    width: '1rem', // Set both width and height to the same value
    height: '1rem',
    // Additional styles if needed to center or adjust the icon
  };

  saveResults() {
    this.manager.defaultSortFunction();

    const json = this.manager.exportToJSON(); // Your method to generate JSON text
    const jsonBlob = new Blob([json], { type: 'application/json' }); // Create a Blob from the JSON data
    const jsonFile = new File([jsonBlob], `${this.arrayRoute$ ?? ''}.json`, {
      type: 'application/json',
    }); // Create a File object

    //uploadFile
    this.fileService
      .uploadFile(jsonFile, this.defaultDirectory, ContentTypes.json)
      .then(() => {

      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      });

    const playerNames = this.manager.getPlayerNames();
    this.db.addDataAndSetId({playerNames: playerNames}, DbKeys.playerNames, this.arrayRoute$ ?? '')
      .then(() => {

      })
      .catch((error) => {
        console.error('Error adding player names:', error);
      });

  }

  async getResults() {
    let path = this.defaultDirectory + '/' + this.arrayRoute$ + '.json';
    try {
      const csv = await this.fileService.getFileText(path);
      this.manager = TournamentManager.fromJson(csv, this.defaultSortFunction);
    } catch (error) {
      this.manager = new TournamentManager([], this.defaultSortFunction);
    }
  }

  addPlayer() {
    this.manager.addNewPlayer();
  }

  deletePlayersMode() {
    this.isDeletingPlayer = true;
    this.table.reset();
  }

  cancelDeletePlayerMode() {
    this.isDeletingPlayer = false;
  }

  deletingDateMode() {
    this.isDeletingDates = true;
  }

  cancelDeletingDateMode() {
    this.isDeletingDates = false;
  }

  deleteDate(date: number) {
    this.manager.deleteDate(date);
  }

  deletePlayer(row: TournamentViewModel2) {
    this.manager.deleteRow(row);
  }

  rowChanged($event: Event, row: any, date: number) {
    const value = ($event.target as HTMLInputElement).value;
    const score = parseFloat(value.replace(',', '.'));
    row.addScore(date, score);
    this.manager.setScoreForDate(date, row, score);
  }
}

import { Component, Input } from '@angular/core';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'app-state-toggle-button',
  templateUrl: './state-toggle-button.component.html',
})
export class StateToggleButtonComponent {
  @Input() isActive: boolean = false;

  // Configurations for both states
  @Input() activeConfig: Button;
  @Input() inactiveConfig: Button;

  // Function to toggle isActive state
  toggleState(): void {
    this.isActive = !this.isActive;
  }
}

// Define a configuration model for button properties
// Define an interface for the base structure without specifying the icon type
interface BaseButton {
  type: 'prime' | 'fontAwesome';
}

// Extend the base interface with specific icon types for each button type
interface PrimeButton extends BaseButton {
  type: 'prime';
  icon: string;
}

interface FontAwesomeButton extends BaseButton {
  type: 'fontAwesome';
  icon: IconDefinition;
}

// Union the specific interfaces to get a discriminated union
type Button = PrimeButton | FontAwesomeButton;


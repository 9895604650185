import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {faPenToSquare, faPlus} from "@fortawesome/free-solid-svg-icons";
import {CampBid, CampService} from "../../../services/db/camp.service";
import {MatDialog} from "@angular/material/dialog";
import {CampBidFOrmComponent} from "./camp-bid-form/camp-bid-form.component";
import {AuthentificationService} from "../../../services/authentification.service";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-camp-bid',
  templateUrl: './camp-bid.component.html',
  styleUrls: ['./camp-bid.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class CampBidComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  @Input() campId: string;

  faPenToSquare = faPenToSquare;
  faPlus = faPlus;
  isLoggedIn: BehaviorSubject<boolean>;
  bids: CampBid[];

  constructor(
    private campService: CampService,
    private matDialog: MatDialog,
    private authService: AuthentificationService,

  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isAuthenticated$
    this.campService.getCampBids(this.campId).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (bids) => {

        this.bids = bids;
      }
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  openAdd() {
    let ref = this.matDialog.open(CampBidFOrmComponent, {
      data: {campId: this.campId}
    });
    ref.afterClosed().pipe(takeUntil(this.destroy$)).subscribe();
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {TeamService} from "../../../services/db/team.service";
import {ITeam} from "../../../models/ITeam";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import {AuthentificationService} from "../../../services/authentification.service";
import {Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-show-teams',
  templateUrl: './show-teams.component.html',
  styleUrls: ['./show-teams.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class ShowTeamsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  teams: ITeam[];
  faPenToSquare = faPenToSquare;
  faTrash = faTrash;

  constructor(
    private teamService: TeamService,
    private auth: AuthentificationService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.teamService.getTeams()
      .pipe(takeUntil(this.destroy$))
      .subscribe(teams => {
      this.teams = teams.sort((a, b) => a.name.localeCompare(b.name));
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  isLogged(): boolean {
    return this.auth.isLoggedIn();
  }

  goToEdit(id: string) {
    this.router.navigate(['/update-team', id])
  }

  delete(id: string) {
    this.router.navigate(['/delete-team', id])
  }
}

import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {FileService} from "../../../services/file/file.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DbKeys} from "../../../services/db/config/db-config";
import {ContentTypes} from "../../../services/db/config/content-types";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DatabaseService} from "../../../services/db/database.service";
import {CampHtmlResult} from "../../../services/db/camp.service";

@Component({
  selector: 'app-upload-file-form',
  templateUrl: './upload-file-form.component.html',
  styleUrls: ['./upload-file-form.component.scss'],
  host: {'class': 'base-host-center styled-background'},
})
export class UploadFileFormComponent implements OnInit {
  @Input() contentType : ContentTypes
  @Input() dbKey : DbKeys
  @Input() title: string;
  @Input() placeholder: string;

  fileForm = this.fb.group({
    fileName: ["", Validators.required],
    file: [undefined, Validators.required],
    fileDate: ["", Validators.required]
  });

  submited: boolean;
  private todayDateString: string;
  private camp: any;


  constructor(
    private fb: FormBuilder,
    private files: FileService,
    private db: DatabaseService,
    private snackbar: MatSnackBar,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() private dialogRef: MatDialogRef<UploadFileFormComponent>
  ) {
    let todayDate = new Date();
    this.todayDateString = todayDate.toLocaleDateString();
    this.submited = false;


    if(data && data.camp) {
      this.contentType = ContentTypes.html;
      this.dbKey = DbKeys.campResults;
      this.title = "Ajouter un résultat";
      this.placeholder = "Titre"
      this.camp = data.camp;
    }
  }

  ngOnInit(): void {
    let todayDate = new Date();
    let todayDateString = todayDate.toLocaleDateString();
    this.fileForm = this.fb.group({
      fileName: ["", Validators.required],
      file: [undefined, Validators.required],
      fileDate: [todayDateString, Validators.required]
    });
  }

  onSubmit($any: any) {
    if(!this.camp) {

      this.submited = true;
      if(this.fileForm.valid) {
        this.files.uploadFile(this.file.value as unknown as File, `/${this.dbKey}`, this.contentType).then(
          (res) => {
            res.ref.getDownloadURL().then(
              (url => {
                this.db.addData({url: url, name: this.fileName.value, date: this.fileDate.value}, this.dbKey).then(
                  () => {this.snackbar.open(`${this.fileName.value} ajouté !`, "Compris")},
                  () => {this.snackbar.open("Attention ! Erreur lors de l'ajout du fichier.", "Compris")})
              }));
          },
          () => {this.snackbar.open("Attention ! Erreur lors de l'ajout du fichier.", "Compris")}
        )
      }
    } else {
      this.submited = true;
      if(this.fileForm.valid) {
        this.files.uploadFile(this.file.value as unknown as File, `/${this.dbKey}/${this.camp.id}`, this.contentType).then(
          (res) => {
            res.ref.getDownloadURL().then(
              (url => {
                this.db.addData({url: url, name: this.fileName.value, date: this.fileDate.value, campId: this.camp.id}, this.dbKey).then(
                  () => {
                    this.snackbar.open(`${this.fileName.value} ajouté !`, "Compris");
                    this.dialogRef.close({url: url, name: this.fileName.value, date: this.fileDate.value, campId: this.camp.id} as CampHtmlResult)
                  },
                  () => {this.snackbar.open("Attention ! Erreur lors de l'ajout du fichier.", "Compris")})
              }));
          },
          () => {this.snackbar.open("Attention ! Erreur lors de l'ajout du fichier.", "Compris")}
        )
      }
    }
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target?.files[0];
      this.fileForm.patchValue({
        file: file
      });
    }
  }

  get file(): FormControl {
    return this.fileForm.controls['file'];
  }

  get fileName(): FormControl {
    return this.fileForm.controls['fileName'];
  }

  get fileDate(): FormControl {
    return this.fileForm.controls['fileDate'];
  }
}

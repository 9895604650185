import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  Action,
  AngularFirestore,
  DocumentChangeAction,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  constructor(private firestore: AngularFirestore) {}

  public addData(data: any, key: string): Promise<DocumentReference<unknown>> {
    return this.firestore.collection(key).add(data);
  }

  public addDataAndSetId(data: any, key: string, id: string): Promise<void> {
    return this.firestore.collection(key).doc(id).set(data);
  }

  public updateData(id: string, data: any, key: string) {
    return this.firestore.collection(key).doc(id).update(data);
  }

  public updateAllData(items: any[], key: string): Promise<void[]> {
    const updatePromises = items.map((item) => {
      return this.updateData(item.id, item, key);
    });
    return Promise.all(updatePromises);
  }

  public deleteData(key: string, id?: string): Promise<void> {
    return this.firestore.collection(key).doc(id).delete();
  }

  public getdata(key: string): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore.collection(key).snapshotChanges();
  }

  public getById(
    key: string,
    id: string
  ): Observable<Action<DocumentSnapshot<unknown>>> {
    return this.firestore.collection(key).doc(id).snapshotChanges();
  }

  public getWhereChained(
    key: string,
    conditions: Condition[]
  ): Observable<any[]> {
    return this.firestore
      .collection(key, (ref) => {
        let query: firebase.default.firestore.Query = ref;

        conditions.forEach((condition) => {
          query = query.where(
            condition.field,
            condition.operator,
            condition.value
          );
        });
        return query;
      })
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as DocumentData;
            const id = a.payload.doc.id;
            return { id, ...data }; // Combine document id with its data
          })
        )
      );
  }
}


export interface Condition {
  field: string;
  operator: firebase.default.firestore.WhereFilterOp;
  value: any;
}

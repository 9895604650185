import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TeamService} from "../../../services/db/team.service";
import {Location} from "@angular/common";

@Component({
  selector: 'app-delete-team-result',
  templateUrl: './delete-team-result.component.html',
  styleUrls: ['./delete-team-result.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class DeleteTeamResultComponent implements OnInit {
  private myDate: Date;

  constructor(
    private activatedRoute: ActivatedRoute,
    private teamService: TeamService,
    private snackbar: MatSnackBar,
    private location: Location
  ) { }

  ngOnInit(): void {
    const dateString = this.activatedRoute.snapshot.queryParamMap.get('myDate');
    if(dateString) {
      this.myDate = new Date(dateString);
    }
  }

  back() {
    this.location.back();
  }

  delete() {
    this.teamService.deleteScores(this.myDate).then(() => {
        //Remove selected date from array
        this.snackbar.open("Scores supprimés", "OK", {duration: 2000});
        this.location.back();

      },
      () => {
        this.snackbar.open("Erreur lors de la suppression des scores", "OK", {duration: 2000});
        console.log("Error deleting scores")
      }
    );
  }

  getFormattedDate() {
    return  this.myDate.toLocaleDateString('fr-FR', {day: '2-digit', month: '2-digit'});
  }
}


import {TournamentViewModel2} from "./TournamentScoreViewModel2";

export class TournamentManager {
  dates: number[] = [];
  viewModels: TournamentViewModel2[];
  private readonly key: string;

  constructor(viewModels: TournamentViewModel2[], key = "sortAscAverage") {
    this.viewModels = viewModels;
    this.updateDates();
    this.key = key;
    this.defaultSortFunction();
  }

  addNewDate(date: number) {
    this.viewModels.forEach(vm => vm.addScore(date, 0));
    this.dates.push(date);
    this.dates.sort();
    this.updateScores();
  }

  addNewPlayer(): void {
    const newPlayer = new TournamentViewModel2("Nouveau", {});
    this.dates.forEach(date => newPlayer.addScore(date, 0));
    this.viewModels.push(newPlayer);
  }

  deleteDate(date: number) {
    this.viewModels.forEach(vm => vm.removeScore(date));
    this.updateDates();
    this.updateScores();
  }

  deleteRow(row: TournamentViewModel2): void {
    const index = this.viewModels.indexOf(row);
    if (index > -1) this.viewModels.splice(index, 1);
  }

  updateAverages(): void {
    this.viewModels.forEach(vm => vm.calculateAverageScore());
  }

  updateTotals(): void {
    this.viewModels.forEach(vm => vm.calculateTotalScores());
  }

  updateScores() {
    this.updateAverages();
    this.updateTotals();
  }

  updateDates(): void {
    const allDates = new Set<number>();
    this.viewModels.forEach(viewModel => {
      Object.keys(viewModel.scoresByDate).forEach(date => allDates.add(Number(date)));
    });
    this.dates = Array.from(allDates).sort((a, b) => a - b);
  }

  exportToJSON(): string {
    return JSON.stringify(this.viewModels);
  }

  static fromJson(json: string, defaultSortFunction: SortFunctionKey): TournamentManager {
    let data;
    try {
      data = JSON.parse(json);
    } catch (error) {
      console.error("Error parsing JSON: " + error);
      return new TournamentManager([], defaultSortFunction);
    }

    if (!Array.isArray(data)) {
      console.error("JSON data is not an array");
      return new TournamentManager([], defaultSortFunction);
    }

    const viewModels = data.map((obj: any) => new TournamentViewModel2(obj.playerName, obj.scoresByDate));
    return new TournamentManager(viewModels, defaultSortFunction);
  }


  sortDescAverage() {
    this.updateScores();

    this.viewModels.sort((a, b) => {
      let averageA = a.averageScore || 0;
      let averageB = b.averageScore || 0;
      return averageB - averageA;
    });
  }

  sortDescTotal() {
    this.updateScores();

    this.viewModels.sort((a, b) => {
      let totalA = a.totalScore || 0;
      let totalB = b.totalScore || 0;
      return totalA - totalB;
    });
  }

  defaultSortFunction() {
    this.updateScores();
    switch (this.key) {
      case SortFunctionKey.SortAscAverage:
        this.sortDescAverage();
        break;
      case SortFunctionKey.SortAscTotal:
        this.sortDescTotal();
        break;
      default:
        console.error(`Sort function ${this.key} not found`);
    }
  }

  setScoreForDate(date: number, row: TournamentViewModel2, score: number) {
    row.addScore(date, score);
    this.updateScores();
    this.defaultSortFunction();
  }

  getPlayerNames(): string[] {
    return this.viewModels.map(vm => vm.playerName);
  }
}

export enum SortFunctionKey {
  SortAscAverage = 'sortAscAverage',
  SortAscTotal = 'sortAscTotal'
}

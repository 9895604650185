import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";
import {TournamentScoreViewModel} from "../../../../models/competition/tournament-score-view-model";
import {SortFunctionKey, TournamentManager} from "../../../../models/competition/tournament-manager";

@Component({
  selector: 'app-monday-results',
  templateUrl: './monday-results.component.html',
  styleUrls: ['./monday-results.component.scss']
})
export class MondayResultsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>()
  arrayRoute$ = new BehaviorSubject<string>("")
  path: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.url
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.arrayRoute$.next( val[0].path ?? "");
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

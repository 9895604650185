import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject, takeUntil} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Editor, Toolbar} from "ngx-editor";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {msWordDefaultColorPalette} from "../../rte/color-presets";
import {DatabaseService} from "../../../services/db/database.service";
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthentificationService} from "../../../services/authentification.service";

@Component({
  selector: 'app-tournament-information',
  templateUrl: './tournament-information.component.html',
  styleUrls: ['./tournament-information.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class TournamentInformationComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<boolean>();

  htmlContent: string;
  form: FormGroup;
  editor: Editor;
  submited: boolean;
  edit = false;
  htmlText: string | null = ''
  safeHtml: SafeHtml

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  route = "tournament-information";
  dbPath = "tournament-information"
  colorPresets = msWordDefaultColorPalette;
  private docId: string;
  dataObject: { html: string };
  isLoggedIn$: BehaviorSubject<boolean>;
  isEditMode = true;

  constructor(private db: DatabaseService,
              private activatedRoute: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private snackbar: MatSnackBar,
              private formBuilder: FormBuilder,
              private auth: AuthentificationService

  ) {
    this.form = this.formBuilder.group({
      html: new FormControl('', { validators: [Validators.required, Validators.minLength(1)] })
    });
    this.editor = new Editor();

  }

  ngOnInit(): void {
    this.auth.isAuthenticated$

    this.isLoggedIn$ = this.auth.isAuthenticated$;
    //Use db.getData to get the first element in angular fire firestore and set it to this.form.get('html')
    this.db.getdata(this.dbPath)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if(true) {
          this.dataObject = data[0].payload.doc.data() as {html: string};
          this.docId = data[0].payload.doc.id;
          this.form.get('html')?.setValue(this.dataObject.html);
          this.htmlText = this.dataObject.html;

        }
      });

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onLoad() {
  }

  onSubmit() {
    this.submited = true;
    if(this.form.valid) {
      if(!this.docId) {
        this.db.addData({html: this.html}, this.dbPath).then(() => {
          this.snackbar.open("Infos enregistrées", "OK", {duration: 3000});
        }).catch((error) => {
          this.snackbar.open("Erreur lors de l'enregistrement", "OK", {duration: 3000});
        });
      } else {
        this.db.updateData(this.docId, {html: this.html}, this.dbPath).then(() => {
          this.snackbar.open("Infos enregistrées", "OK", {duration: 3000});
        }).catch((error) => {
          this.snackbar.open("Erreur lors de l'enregistrement", "OK", {duration: 3000});
        });
      }
    }
  }

  get html() {
    return this.form.get('html')?.value;
  }

  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }

  ngAfterViewInit(): void {
    console.log(`Is edit mode : ${this.isEditMode}`)
    console.log(`Is logged in : ${this.isLoggedIn$}`)
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-competition-cl-perron-teams',
  templateUrl: './competition-cl-perron-teams.component.html',
  styleUrls: ['./competition-cl-perron-teams.component.scss']
})
export class CompetitionClPerronTeamsComponent implements OnInit {
  isLoading: boolean;

  constructor() {
    this.isLoading = true;
  }

  ngOnInit(): void {
  }

  onLoad() {
    this.isLoading = false;
  }

}

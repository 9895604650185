import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clperron-ranking',
  templateUrl: './clperron-ranking.component.html',
  styleUrls: ['./clperron-ranking.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class CLPerronRankingComponent implements OnInit {
  isLoading: any;
  urlString = "https://docs.google.com/spreadsheets/u/2/d/e/2PACX-1vRHPPBTEBVAq7h3W9JNbj0B6dsHiP4ivjPSSEPiJyKDAUmVkS6yfCuH7AIKJnpf9bsC586tioWOMIXG/pubhtml?gid=1077152685&amp;single=true&amp;widget=true&amp;headers=false";

  constructor() {
    this.isLoading = true;
  }

  ngOnInit(): void {
  }


  load() {
    this.isLoading = false;
  }

  isLoadingChangeHandler($event: boolean) {
    this.isLoading = $event;
  }
}

import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {ITeam} from "../../../models/ITeam";
import {TeamService} from "../../../services/db/team.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class CreateTeamComponent implements OnInit {
  teamForm: FormGroup;

  constructor(
    private teamService: TeamService,
    private snackbat: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.teamForm = new FormGroup({
      captain: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required])
    });


  }


  onSubmit() {
    if(this.teamForm.valid){
      this.teamService.addTeam({
        ...this.teamForm.value,
        results: [],
      } as ITeam).then(() => {
        this.snackbat.open('Equipe ajoutée', 'Ok', {
          duration: 2000,
        });
        this.teamForm.markAsPristine();
        this.teamForm.markAsUntouched();
        this.teamForm.reset();


      }).catch((err) => {

        this.snackbat.open('Erreur lors de la création de l\'équipe', 'Compris', {
          duration: 2000,
        });
      });
    }
  }
}

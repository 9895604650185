import {Injectable, OnDestroy} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {BehaviorSubject, first, Observable, Subject, takeUntil, tap} from "rxjs";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService implements OnDestroy {
  destroy$ = new Subject<boolean>();

  constructor(private afAuth: AngularFireAuth) {
    this.isAuthenticated = false;
    this.afAuth.authState.pipe(
      takeUntil(this.destroy$)
    ).subscribe(user => {
      if (user) {
        this.isAuthenticated$.next(true);
      } else {
        this.isAuthenticated$.next(false);
      }
    });
  }

  private isAuthenticated: boolean;
  isAuthenticated$ = new BehaviorSubject<boolean>(false);



  public isLoggedIn(): boolean {
    return this.isAuthenticated;
  }

  public async login(email: string, password: string): Promise<void> {
    try {
      await this.afAuth.signInWithEmailAndPassword(email, password);
      this.isAuthenticated = true;

    } catch (error) {
      this.isAuthenticated = false;

    }
  }

  public async logOut(): Promise<void> {
    try {
      await this.afAuth.signOut();
      this.isAuthenticated = false;
    } catch (error) {

    }
  }

// you subscribe to this in the root component
  public initAuthListener(): Observable<any> {
    return this.afAuth.authState.pipe(
      tap(user => {
        if (user) {
          this.isAuthenticated = true;
        } else {
          this.isAuthenticated = false;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}

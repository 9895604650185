import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CampService} from "../../../../services/db/camp.service";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {CampResultFormComponent} from "../camp-result-form/camp-result-form.component";
import {ICamp} from "../../../../models/camp";
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import {faTrash, faFileArrowUp} from "@fortawesome/free-solid-svg-icons";
import {AuthentificationService} from "../../../../services/authentification.service";
import { StringUtils } from 'src/app/models/utils/string-utils';
import { UpdateCampResultsComponent } from '../update-camp-results/update-camp-results.component';
import {UploadFileFormComponent} from "../../../result/upload-file-form/upload-file-form.component";
import {CampHtmlResultsComponent} from "../../add-camp-sequency/camp-html-results/camp-html-results.component";

@Component({
  selector: 'app-view-camp-result',
  templateUrl: './view-camp-result.component.html',
  styleUrls: ['./view-camp-result.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class ViewCampResultComponent implements OnInit, OnDestroy {
  @Input() campId: string;
  destroy$ = new Subject<boolean>();

  faFileArrowUp = faFileArrowUp;
  faPlus = faPlus;
  isAuthenticated: BehaviorSubject<boolean>;
  camp: ICamp | undefined;
  columnDates: string[];
  columns: any;
  rows: [];
  faPenToSquare = faPenToSquare;
  faTrash = faTrash;
  private playerNames: Map<any, any>;
  private message: string;
  private messageColor: string;
  stringUtils: StringUtils;






  constructor(
    private campService: CampService,
    private authService: AuthentificationService,
    private dialog: MatDialog,
    private auth: AuthentificationService) {
      this.stringUtils = new StringUtils();
      this.message = "";
      this.messageColor = "";
    }

  ngOnInit(): void {

    this.campService.getCampById(this.campId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
      this.camp = data;
      this.campService.getCampPlayers(this.campId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
        this.playerNames = new Map();
        data.forEach(player => {
          this.playerNames.set(player.id, player.firstName + " " + player.lastName);
        });
        this.getColumns();
        this.getRows();
      });

    });

    this.isAuthenticated = this.authService.isAuthenticated$;

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  openAddResults() {
    let addDialog = this.dialog.open(CampResultFormComponent, {
      data: {campId: this.campId}
    });

    addDialog.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
      if(result && this.camp && this.camp.results) {
        this.camp.results.push(result);
        this.camp.results.sort((a, b) => (a.date > b.date) ? 1 : -1);
        this.getColumns();
        this.getRows();
      }
    });
  }

  openEditResults() {
    let editDialog = this.dialog.open(UpdateCampResultsComponent, {
      data: {campId: this.campId, camp: this.camp}
    });
    editDialog.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(camp => {
      if(camp) {
        this.camp = camp;
        this.getColumns();
        this.getRows();
      }
    });
  }

  private getFormattedDate(date: Date) {
    return date.toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit'});
  }




  getColumns() {
    this.columns = [];

    if(this.camp) {
      this.camp.results?.forEach(result => {
        let formattedDate = this.getFormattedDate(result.date);
        this.columns.push({name: formattedDate, prop: formattedDate});
      });
    }

  }



  getRows() {
    let resultMap = new Map();

    if(!this.camp) return;
    this.camp.results?.forEach(result => {
        let formattedDate = this.getFormattedDate(result.date);
        result.scores?.forEach(score => {
          //Must assing to an object at position formattedDate the score
          if (resultMap.has(score.playerId)) {
            let playerScores = resultMap.get(score.playerId);
            playerScores[formattedDate] = score.score.toFixed(2);
            resultMap.set(score.playerId, playerScores);
          } else {
            let playerScores = {};
            // @ts-ignore
            playerScores['name'] = this.playerNames.get(score.playerId);
            // @ts-ignore
            playerScores[formattedDate] = score.score.toFixed(2);
            resultMap.set(score.playerId, playerScores);
          }

        });


        //set an entries array
      // @ts-ignore
      this.rows = Array.from(resultMap.values()).sort((a, b) => (this.getTotal(a) > this.getTotal(b)) ? -1 : 1);

      }
    )
  }

  goToEdit(id: string) {
    //this.router.navigate(['/update-registration', {id: id}]);
  }

  delete(id: string) {
    /*this.registrationsService.deleteData(id).then(
      () => {
        this.registrations = this.registrations.filter(row => row.id !== id)
        this.snackbar.open("Inscription supprimée ! ", "OK")
      },
      () => {
        this.snackbar.open("Erreur lors de la suppression ! ", "Compris !")
      });*/
  }

  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  getTotal(row: any) {
    let total = 0;
    for(const key in row) {
      if(key === 'name') continue;
      const rowElement = row[key];
      total += parseFloat(rowElement);
    }
    return total.toFixed(2);
  }

  openSequencyDialog() {
    const dialogRef = this.dialog.open(CampHtmlResultsComponent,
      {data: {camp: this.camp}});
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadFileFormComponent,
      {data: {camp: this.camp}});
  }
}





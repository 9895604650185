import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-cl-perron-teams',
  templateUrl: './cl-perron-teams.component.html',
  styleUrls: ['./cl-perron-teams.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class ClPerronTeamsComponent implements OnInit {
  isLoading: any;

  constructor() {
    this.isLoading = true;
  }

  ngOnInit(): void {
  }


  load() {
    this.isLoading = false;
  }
}

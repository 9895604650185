import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {CampService} from "../../../services/db/camp.service";
import {ICamp} from "../../../models/camp";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-create-camp',
  templateUrl: './camp-form.component.html',
  styleUrls: ['./camp-form.component.scss'],
  host: {'class': 'base-host-center'}
})
export class CampForm implements OnInit, OnDestroy {
  isEdit = false;
  submitted = false;
  destroy$ = new Subject<boolean>();

  campForm = this.fb.group({
    campName: ['', Validators.required],
    password: ['', Validators.required],
  });
  private id: string;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private campService: CampService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {
    this.isEdit = false;
  }

  ngOnInit(): void {
    this.campForm = this.fb.group({
      campName: ['', Validators.required],
      password: ['', Validators.required],
    });


    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
      const id = params['id'];
        if (id) {
          this.id = id;
            this.isEdit = true;
            this.campService.getCampById(id)
              .pipe(takeUntil(this.destroy$))
              .subscribe(camp => {
                this.campName.setValue(camp?.name);
                this.password.setValue(camp?.password);
            });
        } else {
          console.log("No id")
        }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }


  get campName() {
    return this.campForm.get('campName') as FormControl;
  }

  get password() {
    return this.campForm.get('password') as FormControl;
  }

  onSubmitForm() {
    this.submitted = true;
    if(this.campForm.valid) {
      if(this.isEdit) {
        this.updateCamp();
      } else {
        this.createCamp();
      }
      this.clearForm();
    }
  }

  clearForm() {
    this.campForm.reset();
    this.submitted = false;

  }

  createCamp() {
    let name = this.campName.value;
    let password = this.password.value;
    this.campService.addCamp({name, password} as ICamp)
      .then((camp) =>{
          let id = camp.id;
          let message = this.isEdit ? 'Stage modifié' : 'Stage ajouté';
          this.snackbar.open(message, 'OK', {duration: 2000});

        }, () => {
          let message = this.isEdit ? 'Erreur lors de la modification du stage' : 'Erreur lors de l\'ajout du stage';
          this.snackbar.open(message, 'OK', {duration: 2000});
        }

      )
      .catch(() => {
        let message = this.isEdit ? 'Erreur lors de la modification du camp' : 'Erreur lors de l\'ajout du camp';
        this.snackbar.open(message, 'OK', {duration: 2000});
      });
  }

  updateCamp() {
    let name = this.campName.value;
    let password = this.password.value;
    this.campService.updateCamp(this.id, {name, password} as ICamp)
      .then(() => {
          let message = this.isEdit ? 'Camp modifié' : 'Camp ajouté';
          this.snackbar.open(message, 'OK', {duration: 2000});
        }, () => {
          let message = this.isEdit ? 'Erreur lors de la modification du camp' : 'Erreur lors de l\'ajout du camp';
          this.snackbar.open(message, 'OK', {duration: 2000});
        }

      )
      .catch(() => {
        let message = this.isEdit ? 'Erreur lors de la modification du camp' : 'Erreur lors de l\'ajout du camp';
        this.snackbar.open(message, 'OK', {duration: 2000});
      });
  }

}

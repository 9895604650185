import {DocumentChangeAction} from "@angular/fire/compat/firestore";

export interface Player {
  id?: string;
  campId: string;
  firstName: string;
  lastName: string;
}

export class CampPlayer implements Player {
  id?: string;
  campId: string;
  firstName: string;
  lastName: string;

  constructor(campId: string, firstName: string, lastName: string, id?: string) {
    this.id = id;
    this.campId = campId;
    this.firstName = firstName;
    this.lastName = lastName;
  }

  static CreateFromDoc(data: DocumentChangeAction<Player>) {
    const dataDoc = data.payload.doc.data();
    const id = data.payload.doc.id;
    return new CampPlayer(dataDoc.campId, dataDoc.firstName, dataDoc.lastName, id);
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Tournament, TournamentFactory, TournamentImpl} from "../../../models/tournament";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {StringUtils} from "../../../models/utils/string-utils";
import {RegistrationService} from "../../../services/db/registration.service";
import {TournamentService} from "../../../services/db/tournament.service";
import {Registration, RegistrationFactory} from "../../../models/registration";
import {ActivatedRoute, Router} from "@angular/router";
import {DatabaseService} from "../../../services/db/database.service";
import {DbKeys} from "../../../services/db/config/db-config";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Location} from "@angular/common";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-update-registration',
  templateUrl: './update-registration.component.html',
  styleUrls: ['./update-registration.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class UpdateRegistrationComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  data: Tournament[];
  registrationForm = new FormGroup({
    tournamentId: new FormControl(''),
    player1: new FormControl(''),
    player2: new FormControl(''),
    isReserve: new FormControl(false),
  })
  messageColor: any;
  message: string;
  submitted: boolean;
  stringUtils: StringUtils;
  getDateAndLocationString = StringUtils.getDateAndLocationString;
  private id: string | null;
  private registration: Registration;
  private tournament: Tournament | undefined;

  constructor(
    private registrationService: RegistrationService,
    private tournamentService: TournamentService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private db: DatabaseService,
    private router: Router,
    private snackbar: MatSnackBar,
    private location: Location
  ) {
    this.message = "";
    this.messageColor = 'text-primary';
    this.stringUtils = new StringUtils();
  }


  ngOnInit(): void {

    this.tournamentService.getTournaments()
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
      this.data = res;
        this.tournament = this.data.find(tournament => tournament.id === this.registration.tournamentId);
    });
    this.registrationForm = this.formBuilder.group({
      tournamentId: ['', Validators.required],
      player1: ['', [Validators.required, Validators.minLength(3)]],
      player2: ['', [Validators.required, Validators.minLength(3)]],
      isReserve: [false]
    });

    this.id = this.route.snapshot.paramMap.get('id');
    this.db.getById(DbKeys.registrations, this.id || "")
      .pipe(takeUntil(this.destroy$))
      .subscribe(docElement => {
      this.registration = RegistrationFactory.createFromDoc(docElement);
      this.registrationForm.reset(this.registration);


    });

    this.tournamentId?.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(tournamentId => {
      this.tournament = this.data.find(tournament => tournament.id === tournamentId);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get player1() {
    return this.registrationForm.get("player1")
  }

  get player2() {
    return this.registrationForm.get("player2")
  }

  get isReserve() {
    return this.registrationForm.get("isReserve")
  }


  get tournamentId() {
    return this.registrationForm.get("tournamentId")
  }

  onSubmit(value: Registration): void {
    this.submitted = true;

    if (this.registrationForm.invalid) {
      return;
    }

    if (!this.tournament) {
      this.showSnackbar("Tournoi introuvable");
      return;
    }

    const reserveChanged = this.tournament.maxReserveRegistrations
      && this.tournament.maxReserveRegistrations > 0
      && (this.registration.isReserve !== value.isReserve);

    const tournamentChanged = this.registration.tournamentId !== value.tournamentId;

    if (tournamentChanged) {
      const isReserve = value.isReserve;
      const hasFreeSpot = isReserve ? this.reserveHasFreeSpot() : this.registrationHasFreeSpot();

      if (!hasFreeSpot) {
        const message = isReserve
          ? "Nombre maximum d'inscriptions en réserve atteint pour ce tournoi"
          : "Nombre maximum d'inscriptions atteint pour ce tournoi";
        this.showSnackbar(message);
        return;
      }
    } else if (reserveChanged) {
      if (value.isReserve && this.tournament.reserveRegistrationCount >= this.tournament.maxReserveRegistrations) {
        this.showSnackbar("Nombre maximum d'inscriptions en réserve atteint pour ce tournoi");
        return;
      }

      if (!value.isReserve && this.tournament.registrationCount >= this.tournament.maxRegistrations) {
        this.showSnackbar("Nombre maximum d'inscriptions atteint pour ce tournoi");
        return;
      }
    }

    this.updateRegistration(value);
  }


  private reserveHasFreeSpot(): boolean {
    if(this.tournament !== null && this.tournament !== undefined) {
      if(this.tournament.reserveRegistrationCount < this.tournament.maxReserveRegistrations) {
        return true;
      }
    }

    return false;
  }

  private registrationHasFreeSpot() {
    if(this.tournament !== null && this.tournament !== undefined) {
      if(this.tournament.registrationCount < this.tournament.maxRegistrations) {
        return true;
      }
    }

    return false;
  }

  private showSnackbar(message: string): void {
    this.snackbar.open(message, "OK", { duration: 2000 });
  }

  private updateRegistration(value: Registration) {
    if(this.id !== null && this.id !== undefined) {
      this.db.updateData(this.id, value, DbKeys.registrations)
        .then(
          () => {
            this.snackbar.open("Inscription modifiée", "OK", {duration: 2000});
            this.router.navigate(['/registeration-list']);
          },
          (err) => this.snackbar.open("Erreur lors de la modification de l'inscription", "OK", {duration: 2000}));
    }
  }

  onReset(): void {
    this.submitted = false;
    this.registrationForm.reset();
  }

  goBack(): void {
    this.location.back();
  }

}

import {Action, DocumentChangeAction, DocumentSnapshot} from "@angular/fire/compat/firestore";

export interface Registration {
  id: string;
  tournamentId: string;
  player1: string;
  player2: string;
  dateInscription?: Date
  isReserve?: boolean
}

export class RegistrationFactory {
  static create(id: string, tournamentId: string, player1: string, player2: string, isReserve?: boolean): Registration {
    return {
      id: id,
      tournamentId: tournamentId,
      player1: player1,
      player2: player2,
      isReserve: isReserve
    }
  }

  static createFromDoc(doc: Action<DocumentSnapshot<unknown>>) {
    return {
      id:doc.payload.id,
      tournamentId: doc.payload.get("tournamentId"),
      player1: doc.payload.get("player1"),
      player2: doc.payload.get("player2"),
      dateInscription: doc.payload.get('dateInscription').toDate(),
      isReserve: doc.payload.get('isReserve')
    }
  }

  static createFromDocChangeAction(doc: DocumentChangeAction<unknown>) {
    return {
      id: doc.payload.doc.id,
      tournamentId: doc.payload.doc.get("tournamentId"),
      player1: doc.payload.doc.get("player1"),
      player2: doc.payload.doc.get("player2"),
      dateInscription: doc.payload.doc.get('dateInscription')?.toDate(),
      isReserve: doc.payload.doc.get('isReserve')
    }
  }
}







import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {FileService} from "../../../../services/file/file.service";
import {ContentTypes} from "../../../../services/db/config/content-types";
import {PicturesService} from "../../../../services/db/pictures.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-add-picture',
  templateUrl: './add-picture.component.html',
  styleUrls: ['./add-picture.component.scss']
})
export class AddPictureComponent implements OnInit {
  campId: string;
  formGroup = this.fb.group({
    file: [undefined, Validators.required],
  });
  isAdding = false;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {campId: string, images: string[]},
    private files: FileService,
    private pictures: PicturesService,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<AddPictureComponent>
  ) { }

  ngOnInit(): void {
    this.campId = this.data.campId;

    this.formGroup = this.fb.group({
      file: [undefined, Validators.required],
    });

    console.log(this.data)
  }

  onFileChange(event: any) {
    console.log(this.formGroup)
    if (event.target.files.length > 0) {
      const file = event.target?.files[0];
      this.formGroup.patchValue({
        file: file
      });
    }
  }

  onUpload() {
    this.isAdding = true;
    if(this.formGroup.valid) {
      this.files.uploadFile(this.file.value as unknown as File, "/pictures", ContentTypes.images).then(
        (res) => {
          res.ref.getDownloadURL().then(
            (url => {
              // Add the picture URL to the images array immediately
              this.data.images.push(url);

              this.pictures.addPictureForEntity(this.campId, url).then(
                () => {
                  this.snackbar.open("Photo ajoutée", "OK", {duration: 2000});
                  this.formGroup.reset({ file: undefined });
                  this.dialogRef.close({url: url, manuallyAdded: true});
                },
                () => {
                  // If the Firestore call fails, remove the picture URL from the images array
                  const index = this.data.images.indexOf(url);
                  if (index > -1) {
                    this.data.images.splice(index, 1);
                  }

                  this.snackbar.open("Erreur lors de l'ajout de la photo", "OK", {duration: 2000});
                  this.formGroup.reset({ file: undefined });
                  this.dialogRef.close();
                }
              )
            })
          )
        },
        () => {
          this.snackbar.open("Erreur lors de l'ajout de la photo", "OK", {duration: 2000});
          this.formGroup.reset({ file: undefined });
          this.dialogRef.close();
        }
      ).finally(() => this.isAdding = false);
    }
  }

  get file() {
    return this.formGroup.get('file') as FormControl;
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'bridgepro-d6489',
    appId: '1:348209906007:web:8b34e7539862ef20d3be12',
    storageBucket: 'bridgepro-d6489.appspot.com',
    apiKey: 'AIzaSyAeSOh0aMQzGcUb_fPzO_DjLFwWashWiyI',
    authDomain: 'bridgepro-d6489.firebaseapp.com',
    messagingSenderId: '348209906007',
    databaseURL: 'bridgepro-d6489-default-rtdb.europe-west1.firebasedatabase.app/',
    measurementId: 'G-23QE6MPY5R',
  },
  production: false
};




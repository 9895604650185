import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-clp-results',
  templateUrl: './clp-results.component.html',
  styleUrls: ['./clp-results.component.scss']
})
export class ClpResultsComponent implements OnInit {
  destroy$ = new Subject<boolean>()
  arrayRoute$ = new BehaviorSubject<string>("")
  path: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.url
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.arrayRoute$.next( val[0].path ?? "");
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}

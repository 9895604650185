import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {DatabaseService} from "../../services/db/database.service";
import {DbKeys} from "../../services/db/config/db-config";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {AuthentificationService} from "../../services/authentification.service";
import {faArrowUp, faTrash, faArrowDown, faSave, faPenToSquare, faPlus} from "@fortawesome/free-solid-svg-icons";
import {News} from "../../models/news";
import {NewsService} from "../../services/db/news.service";
import {Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";


@Component({
  selector: 'app-infos',
  templateUrl: './infos.component.html',
  styleUrls: ['./infos.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class InfosComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;
  faTrash = faTrash;
  faSave = faSave;
  faPlus = faPlus
  faPenToSquare = faPenToSquare;
  news: News[];
  isLoading = true;

  constructor(
    private db: DatabaseService,
    private snackbar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private auth: AuthentificationService,
    private newsService: NewsService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.db.getdata(DbKeys.news)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: (data) => {
        // @ts-ignore
        this.news = data.map(row => {
          return {
            html: this.sanitizer.bypassSecurityTrustHtml(row.payload.doc.get("html")),
            order: row.payload.doc.get("order"),
            id: row.payload.doc.id
          } as News;
        }).sort((a, b) => (a?.order || 0) - (b?.order || 0));
        this.isLoading = false;
      },
      complete: () => {
      },
      error: () => {
        this.snackbar.open("Erreur lors du chargement des news.", "Compris")
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  up(index: number) {
    if (index === 0) {
      this.news.push(this.news.splice(index, 1)[0]);
    } else {
      let temp = this.news[index - 1];
      this.news[index - 1] = this.news[index];
      this.news[index] = temp;
    }

    // Update the order field of the moved news item
    this.news[index].order = this.news[index - 1].order;
    this.newsService.setNewsOrder(this.news).then(
      () => {
        console.log("Success")},
      () => {
        console.log("Error")}
    )
  }

  down(index: number) {
    if (index === this.news.length - 1) {
      this.news.unshift(this.news.splice(index, 1)[0]);
    } else {
      let temp = this.news[index + 1];
      this.news[index + 1] = this.news[index];
      this.news[index] = temp;
    }

    // Update the order field of the moved news item
    this.news[index].order = this.news[index + 1].order;
    this.newsService.setNewsOrder(this.news).then(
      () => {
        console.log("Success")},
      () => {
        console.log("Error")}
    )
  }

  delete(index: number) {
    this.db.deleteData(DbKeys.news, this.news[index].id).then(() => {
    }, () => {
    })
  }




  save() {

  }


  goToEdit(i: News) {

    this.router.navigate(['/edit-news', i.id]);
  }

  goToAdd() {
    this.router.navigate(['/rte'])
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {FileService} from "../../../services/file/file.service";
import {ResultService} from "../../../services/db/result.service";
import {FileData, FileDataFactory} from "../../../models/fileData";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DatabaseService} from "../../../services/db/database.service";
import {DbKeys} from "../../../services/db/config/db-config";
import {AuthentificationService} from "../../../services/authentification.service";
import {StringUtils} from "../../../models/utils/string-utils";
import {pipe, Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-result-list',
  templateUrl: './result-list.component.html',
  styleUrls: ['./result-list.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class ResultListComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  results: FileData[];
  faTrash = faTrash;

  constructor(
    private resultService: ResultService,
    private fileService: FileService,
    private snackbar: MatSnackBar,
    private db: DatabaseService,
    private auth: AuthentificationService
  ) {
    this.results = [];
  }

  getDateSmallFormat = StringUtils.getDateSmallFormat;

  ngOnInit(): void {
    this.resultService.getdata()
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
      this.results = res.map(r => FileDataFactory.createFromDoc(r)).sort((a, b) => b.date.getTime() - a.date.getTime());

    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  delete(result: FileData) {
    this.fileService.deleteFile(result.url)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
      {
        complete: () => {
          this.deleteCommentary(result);
        },
        error: () => {
          this.deleteCommentary(result);
        }
      }
    );
  }

  private deleteCommentary(result: FileData) {
    this.db.deleteData(DbKeys.results, result.id)
      .then(() => this.snackbar.open("Suppression réussie.", "Fermer", {duration: 3000}))
      .catch(() => this.snackbar.open("Erreur de suppression.", "Fermer", {duration: 3000}));
  }

  private showErrorSnackbar() {
    this.snackbar.open("Erreur de suppression.", "Fermer")
  }

  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }
}

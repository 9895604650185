import {Component, OnDestroy, OnInit} from '@angular/core';
import {SpreadsheetServiceService} from "../../services/spreadsheet-service.service";
import {IconCategory} from "@igniteui/material-icons-extended";
import values = IconCategory.values;
import {object} from "@angular/fire/database";
import {HCP} from "../../models/tables/hcp";
import {FormBuilder, FormControl} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-handicap22',
  templateUrl: './handicap22.component.html',
  styleUrls: ['./handicap22.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class Handicap22Component implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  hcp: HCP[];

  private backup:  HCP[];
  isLoading: boolean;
  headers: Map<string, number>;
  form = this.fb.group({
    filterText: [""]
  });

  constructor(private sheetService: SpreadsheetServiceService,
              private fb: FormBuilder) {
    this.isLoading = true;
    this.headers = new Map<string, number>();
    this.headers.set("nom", 0);
    this.headers.set("license", 1);
    this.headers.set("hcp", 2)
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      filterText: [""]
    });

    this.sheetService
      .getSpreadSheetCsv("https://docs.google.com/spreadsheets/d/e/2PACX-1vSaOUArOZ6bmRZiwRotYkexD1y-fPcWkLcfkoKt4Jzw7J18sqrhIwOdyl4FBBOCeky8S7R782UzyWKw/pub?gid=0&single=true&output=csv")
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {

        this.hcp = this.sheetService.csvToJson(res, this.headers, [0, 1]) as unknown as HCP[];
        this.backup = this.hcp;
        this.isLoading = false;
      });
    this.filterText?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
      () => {
        let text = this.filterText?.value
        if(text !== null && text !== undefined) {
          this.hcp = this.backup.filter(t => (t.hcp+t.nom+t.license).toLowerCase().includes(text!.toString().toLowerCase()))
        }
          }
    )

  }

  get filterText() {
    return this.form.get("filterText")
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}

import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {CampService} from "../../../../services/db/camp.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CampPlayer, Player} from "../../../../models/camp/player";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-camp-player-form',
  templateUrl: './camp-player-form.component.html',
  styleUrls: ['./camp-player-form.component.scss']
})
export class CampPlayerFormComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();


  formGroup = this.getForm();
  submitted = false;
  editingMode = false;
  private player: Player;
  private campPlayers: CampPlayer[] = [];

  constructor(
    private fb: FormBuilder,
    private campService: CampService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: {campId: string, player: Player},
    private dialogRef: MatDialogRef<CampPlayerFormComponent>
  ) { }

  ngOnInit(): void {
    this.formGroup = this.getForm();

    if(this.data.player) {
      this.player = Object.assign({}, this.data.player);
      this.firstName.setValue(this.data.player.firstName);
      this.lastName.setValue(this.data.player.lastName);
      this.editingMode = true;
    }

    if(!this.editingMode) {
      this.dialogRef.beforeClosed()
      .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
        console.log("closed")
        this.dialogRef.close(this.campPlayers);
      });
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getForm() {
    return this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    });
  }

  get firstName() {
    return this.formGroup.get('firstName') as FormControl;
  }

  get lastName() {
    return this.formGroup.get('lastName') as FormControl;
  }

  onSubmit() {
    this.submitted = true;
    if(this.formGroup.valid) {
      console.log(this.formGroup.value)

      if(this.editingMode) {
        this.updateCampPlayer();
      } else {
        this.createCampPlayer();
      }
    }
  }

  private createCampPlayer() {
    let CampPlayer = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      campId: this.data.campId,
    } as CampPlayer;
    this.campService.addCampPlayer(CampPlayer).then((result) => {
      CampPlayer.id = result.id;
        console.log(CampPlayer)
        this.formGroup = this.getForm();
        this.snackbar.open("Joueur ajouté", 'Compris', {
          duration: 3000,
        });
        this.campPlayers.push(CampPlayer);
      },
      (error) => {
        this.snackbar.open("Erreur lors de l'ajout", 'Compris', {
          duration: 3000,
        });
      });
  }

  private updateCampPlayer() {
    this.player.firstName = this.firstName.value;
    this.player.lastName = this.lastName.value;




    this.campService.updateCampPlayer(this.player.id || '', this.player).then(
      () => {
        this.snackbar.open("Joueur modifié", 'Compris', {duration: 3000});
        this.dialogRef.close({ data: this.player }); // Pass the player object as part of a result object

      },
      () => {
        this.snackbar.open("Erreur lors de la modification", 'Compris', {duration: 3000});
        this.dialogRef.close();
      }
    )
  }

  onCloseCreate() {
    console.log("Camp players ")
    console.log(this.campPlayers)
    this.dialogRef.close(this.campPlayers);
  }
}

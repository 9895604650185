import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthentificationService} from "../../services/authentification.service";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {BehaviorSubject, Subject} from "rxjs";
import {DynamicTitleService} from "../../services/db/dynamic-title.service";

@Component({
  selector: 'app-new-nav-bar',
  templateUrl: './new-nav-bar.component.html',
  styleUrls: ['./new-nav-bar.component.scss'],
})
export class NewNavBarComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  faGear = faGear;
  faRightFromBracket = faRightFromBracket;
  isLoggedIn: BehaviorSubject<boolean>;
  menuOpened = false;

  //declare constant coupeRoute variable = coupe-club
  coupeRoute = 'coupe-club';
  protected coupeTitle: string | undefined;

  constructor(
    private authService: AuthentificationService,
    private router: Router,
    private titleService: DynamicTitleService,
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isAuthenticated$

    this.titleService.getTitleByRoute(this.coupeRoute).
    then(title => {
      this.coupeTitle = title?.title;
    }).catch(err => {
      console.log(err);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }


  logout() {
    this.authService.logOut().then(r => console.log("Logged out."));
  }
  login() {
    const currentUrl = this.router.url;
    this.router.navigate(['/login', { previousRoute: currentUrl }]);
  }

  onOpenMenu() {
    this.menuOpened = true;
  }
}

import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentData,
  DocumentReference, QuerySnapshot
} from "@angular/fire/compat/firestore";
import {DbKeys} from "./config/db-config";
import {IPicture} from "../../models/picture";
import {catchError, from, map, Observable, throwError} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PicturesService {
  private picturesCollection: AngularFirestoreCollection<IPicture>;

  constructor(
    private afs: AngularFirestore,
  ) {
    this.picturesCollection = this.afs.collection<IPicture>(DbKeys.pictures);
  }

  async addPictureForEntity(entityId: string, pictureUrl: string): Promise<void> {
    const querySnapshot = await this.picturesCollection.ref.where('entityId', '==', entityId)
      .get();
    if (querySnapshot.size > 0) {
      // Entity already has pictures, add the new picture to picturesUrl array
      const pictureDoc = querySnapshot.docs[0];
      const pictureData = pictureDoc.data() as IPicture;
      const picturesUrl = pictureData.picturesUrl.concat(pictureUrl);
      return pictureDoc.ref.update({picturesUrl}).then(() => {
      });
    } else {
      // Entity doesn't have any pictures, create a new picture document
      const pictureData_1: IPicture = {entityId, picturesUrl: [pictureUrl]};
      return this.picturesCollection.add(pictureData_1).then(() => {
      });
    }
  }

  async removePictureForEntity(entityId: string, pictureUrl: string): Promise<void> {
    const querySnapshot = await this.picturesCollection.ref.where('entityId', '==', entityId).get();
    if (querySnapshot.size > 0) {

      // Entity has pictures, remove the picture from picturesUrl array
      const pictureDoc = querySnapshot.docs[0];
      const pictureData = pictureDoc.data() as IPicture;
      const picturesUrl = pictureData.picturesUrl.filter(url => url !== pictureUrl);
      return pictureDoc.ref.update({picturesUrl}).then(() => {
      });
    } else {
      // Entity doesn't have any pictures
      return Promise.resolve();
    }
  }

  getPicturesForEntity(entityId: string): Observable<IPicture | null> {
    console.log('getPicturesForEntity', entityId)
    console.log(`requestString : entityId == ${entityId}`)
    const picturesCollection: AngularFirestoreCollection<IPicture> = this.afs.collection<IPicture>(DbKeys.pictures, ref => ref.where('entityId', '==', entityId));

    return picturesCollection.snapshotChanges().pipe(
      map(actions => {
        if (actions.length > 0) {
          const pictureDoc = actions[0].payload.doc;
          const pictureData = pictureDoc.data() as IPicture;
          return pictureData as IPicture;
        } else {
          return null;
        }
      }),
      catchError(error => {
        console.error('Error getting pictures for entity:', error);
        return throwError(error);
      })
    ) as Observable<IPicture | null>;
  }




}

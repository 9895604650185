import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-competitions',
  templateUrl: './competitions.component.html',
  styleUrls: ['./competitions.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class CompetitionsComponent implements OnInit {
  links = [
    {url: "https://www.rbbf.be/node/234", title: "Divisions Nationales"},
    {url: "https://www.lbf.be/index.php/championnat-lbf/resultats-et-classement", title: "Super Ligue"},
    {url: "http://www.bridgecomiteliege.be/", title: "Provinciales"},
    {url: "http://zweiffel.be/app/division.php", title: "Coupe Zweiffel"},
  ];

  constructor() { }

  ngOnInit(): void {
  }

}

export class Player {
  private readonly _id: string | undefined;
  private _name: string;


  constructor(nom: string, id?: string) {
    this._id = id;
    this._name = nom;
  }


  get id(): string {
    return <string>this._id;
  }


  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  static fromData(data: { name: string, id: string }): Player {
    return new Player(data.name, data.id);
  }

  toData() {
    return {name: this.name, id: this.id}
  }
}

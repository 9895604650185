import { Component, OnInit } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-my-competition-system',
  templateUrl: './my-competition-system.component.html',
  styleUrls: ['./my-competition-system.component.scss']
})
export class MyCompetitionSystemComponent implements OnInit {
  url: SafeResourceUrl;
  private readonly urlText: string;
  isLoading: boolean;

  constructor(
    private sanitizer: DomSanitizer
  ) {
    this.isLoading = true;
    this.urlText = "https://firebasestorage.googleapis.com/v0/b/bridgepro-d6489.appspot.com/o/pdf%2FVC%2006-2022.pdf?alt=media&token=60d109d2-f971-44d5-82d3-e82c51791610"
  }

  ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlText);
  }

  load() {
    this.isLoading = false;
  }
}

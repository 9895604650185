import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TeamService} from "../../../services/db/team.service";
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Location} from "@angular/common";
import {AuthentificationService} from "../../../services/authentification.service";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-update-team',
  templateUrl: './update-team.component.html',
  styleUrls: ['./update-team.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class UpdateTeamComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  teamForm: any;
  private id: string;
  loggedIn: any;


  constructor(
    private teamService: TeamService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private location: Location,
    private auth: AuthentificationService,
  ) {
  }

  ngOnInit(): void {
    this.teamForm = new FormGroup({
      captain: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required])
    });

    this.route.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
      this.id = params.get('id') || "";
      this.teamService.getTeamById(this.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(team => {
        this.teamForm.controls.captain.setValue(team.captain);
        this.teamForm.controls.name.setValue(team.name);
      });
    });

    this.loggedIn = this.auth.isLoggedIn();

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onSubmit() {
    if(this.teamForm.valid) {
      this.teamService.updateTeam(this.id, this.teamForm.value)
        .then(() => {
          this.snackbar.open('Equipe mise à jour !', 'OK', {duration: 2000});
          this.location.back();

        })
        .catch(() => {
          this.snackbar.open('Erreur lors de la mise à jour de l\'équipe', 'OK', {duration: 2000})
        });
    }
  }

  preventDefault($event: MouseEvent) {
    $event.preventDefault();
    this.location.back();
  }
}
